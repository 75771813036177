import Icon from "@ant-design/icons";
import React from "react";

function CustomExerciseLgIcon(props) {
    const TestSvg = () => (
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.73291" width="64" height="64" rx="32" fill="#BBDAFF" />
            <g clipPath="url(#clip0_1360_35408)">
                <path
                    d="M43.2551 14.7329H42.1546C41.3026 14.7329 40.6055 15.43 40.6055 16.282V18.5913H44.8042V16.282C44.8042 15.43 44.1072 14.7329 43.2551 14.7329Z"
                    fill="#3C8AFF"
                />
                <path
                    d="M43.7545 14.8176C43.5975 14.7636 43.4299 14.7329 43.2551 14.7329H42.1546C41.3026 14.7329 40.6055 15.43 40.6055 16.282V18.5913H42.7048V16.282C42.7048 15.6048 43.1459 15.0268 43.7545 14.8176Z"
                    fill="#3B8AFF"
                />
                <path
                    d="M47.5959 23.8289H16.4057C15.0612 24.2011 14.0664 25.4381 14.0664 26.8968V45.4455C14.0664 47.1962 15.4988 48.6286 17.2496 48.6286H29.0129C29.4104 48.6286 29.7546 48.8853 29.8906 49.2588C30.2038 50.1188 31.0284 50.7329 31.9967 50.7329C32.965 50.7329 33.7896 50.1188 34.1028 49.2588C34.2388 48.8853 34.583 48.6286 34.9805 48.6286H46.7521C48.5028 48.6286 49.9352 47.1962 49.9352 45.4455V26.8968C49.9352 25.4381 48.9404 24.2011 47.5959 23.8289Z"
                    fill="#3C8AFF"
                />
                <path
                    d="M16.8292 45.4455V26.8968C16.8292 25.4381 17.824 24.2011 19.1685 23.8289H16.4057C15.0612 24.2011 14.0664 25.4381 14.0664 26.8968V45.4455C14.0664 47.1962 15.4988 48.6286 17.2496 48.6286H20.0124C18.2617 48.6286 16.8292 47.1962 16.8292 45.4455Z"
                    fill="#0057DA"
                />
                <path
                    d="M32.6523 49.2588C32.5163 48.8853 32.172 48.6287 31.7746 48.6287H29.0117C29.4091 48.6287 29.7534 48.8853 29.8894 49.2588C30.2026 50.1188 31.0272 50.7329 31.9955 50.7329C32.5171 50.7329 32.9962 50.5535 33.3769 50.2546C33.051 49.9987 32.7967 49.6555 32.6523 49.2588Z"
                    fill="#0057DA"
                />
                <path
                    d="M46.2237 21.5972C45.877 21.5972 33.6421 21.5972 33.2997 21.5972C32.5409 21.5972 31.9258 22.2123 31.9258 22.971V44.8767C31.9258 45.6355 32.5409 46.2506 33.2997 46.2506H46.2237C46.9824 46.2506 47.5975 45.6355 47.5975 44.8767V22.971C47.5975 22.2123 46.9824 21.5972 46.2237 21.5972Z"
                    fill="#F4F9FF"
                />
                <path
                    d="M34.6887 44.8767V22.971C34.6887 22.2123 35.3038 21.5972 36.0625 21.5972C34.52 21.5972 33.3999 21.5972 33.2997 21.5972C32.5409 21.5972 31.9258 22.2123 31.9258 22.971V44.8767C31.9258 45.6355 32.5409 46.2506 33.2997 46.2506H36.0625C35.3038 46.2506 34.6887 45.6355 34.6887 44.8767Z"
                    fill="#E4F0FF"
                />
                <path
                    d="M38.5115 26.3502H35.305C35.005 26.3502 34.7617 26.107 34.7617 25.807C34.7617 25.5069 35.005 25.2637 35.305 25.2637H38.5115C38.8115 25.2637 39.0548 25.5069 39.0548 25.807C39.0548 26.107 38.8115 26.3502 38.5115 26.3502Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M38.5115 28.6342H35.305C35.005 28.6342 34.7617 28.3909 34.7617 28.0909C34.7617 27.7908 35.005 27.5476 35.305 27.5476H38.5115C38.8115 27.5476 39.0548 27.7908 39.0548 28.0909C39.0548 28.3909 38.8115 28.6342 38.5115 28.6342Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M38.5115 30.9179H35.305C35.005 30.9179 34.7617 30.6746 34.7617 30.3746C34.7617 30.0745 35.005 29.8313 35.305 29.8313H38.5115C38.8115 29.8313 39.0548 30.0745 39.0548 30.3746C39.0548 30.6747 38.8115 30.9179 38.5115 30.9179Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M38.5115 33.2021H35.305C35.005 33.2021 34.7617 32.9588 34.7617 32.6588C34.7617 32.3587 35.005 32.1155 35.305 32.1155H38.5115C38.8115 32.1155 39.0548 32.3587 39.0548 32.6588C39.0548 32.9588 38.8115 33.2021 38.5115 33.2021Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M38.5115 35.4857H35.305C35.005 35.4857 34.7617 35.2425 34.7617 34.9425C34.7617 34.6424 35.005 34.3992 35.305 34.3992H38.5115C38.8115 34.3992 39.0548 34.6424 39.0548 34.9425C39.0548 35.2425 38.8115 35.4857 38.5115 35.4857Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M39.7117 37.7697H35.305C35.005 37.7697 34.7617 37.5264 34.7617 37.2264C34.7617 36.9263 35.005 36.6831 35.305 36.6831H39.7117C40.0117 36.6831 40.255 36.9263 40.255 37.2264C40.255 37.5264 40.0117 37.7697 39.7117 37.7697Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M44.3695 40.0536H35.305C35.005 40.0536 34.7617 39.8104 34.7617 39.5103C34.7617 39.2103 35.005 38.967 35.305 38.967H44.3695C44.6696 38.967 44.9128 39.2103 44.9128 39.5103C44.9128 39.8104 44.6696 40.0536 44.3695 40.0536Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M44.3695 42.3376H35.305C35.005 42.3376 34.7617 42.0943 34.7617 41.7943C34.7617 41.4942 35.005 41.251 35.305 41.251H44.3695C44.6696 41.251 44.9128 41.4942 44.9128 41.7943C44.9128 42.0943 44.6696 42.3376 44.3695 42.3376Z"
                    fill="#B4DBFF"
                />
                <path d="M44.805 33.1506L42.7056 33.2954L42.4883 20.1431H44.805V33.1506Z" fill="#90CAFF" />
                <path d="M42.7048 33.2954L40.6055 33.1506V20.1431H42.7048V33.2954Z" fill="#3B8AFF" />
                <path d="M40.6055 18.4465H44.8042V20.3802H40.6055V18.4465Z" fill="#DFEBFA" />
                <path d="M40.6055 18.4465H42.7048V20.3802H40.6055V18.4465Z" fill="#B4DBFF" />
                <path d="M43.9774 35.0749L44.8042 33.1506H40.6055L41.4323 35.0749H43.9774Z" fill="#B4DBFF" />
                <path d="M40.6055 33.1506L41.4323 35.0749H43.5317L42.7048 33.1506H40.6055Z" fill="#8BCAFF" />
                <path d="M41.4023 35.0024L42.2603 36.9991C42.4285 37.3905 42.9834 37.3905 43.1516 36.9991L44.0096 35.0024H41.4023Z" fill="#685E68" />
                <path
                    d="M43.5017 35.0024H41.4023L42.2603 36.9991C42.4285 37.3905 42.9834 37.3905 43.1516 36.9991L43.7556 35.5934L43.5017 35.0024Z"
                    fill="#554E56"
                />
                <path
                    d="M30.6233 21.5972H17.7801C17.0213 21.5972 16.4062 22.2123 16.4062 22.9711V44.8768C16.4062 45.6355 17.0213 46.2506 17.7801 46.2506H30.6233C31.3821 46.2506 31.9972 45.6355 31.9972 44.8768V22.9711C31.9972 22.2123 31.3821 21.5972 30.6233 21.5972Z"
                    fill="#F4F9FF"
                />
                <path
                    d="M19.1691 44.8767V22.971C19.1691 22.2123 19.7842 21.5972 20.543 21.5972H17.7801C17.0213 21.5972 16.4062 22.2123 16.4062 22.971V44.8767C16.4062 45.6355 17.0213 46.2506 17.7801 46.2506H20.543C19.7842 46.2506 19.1691 45.6355 19.1691 44.8767Z"
                    fill="#E4F0FF"
                />
                <path
                    d="M28.6937 26.3502H19.6292C19.3292 26.3502 19.0859 26.107 19.0859 25.807C19.0859 25.5069 19.3292 25.2637 19.6292 25.2637H28.6937C28.9938 25.2637 29.237 25.5069 29.237 25.807C29.237 26.107 28.9937 26.3502 28.6937 26.3502Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 28.6342H19.6292C19.3292 28.6342 19.0859 28.3909 19.0859 28.0909C19.0859 27.7908 19.3292 27.5476 19.6292 27.5476H28.6937C28.9938 27.5476 29.237 27.7908 29.237 28.0909C29.237 28.3909 28.9937 28.6342 28.6937 28.6342Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 30.9179H19.6292C19.3292 30.9179 19.0859 30.6746 19.0859 30.3746C19.0859 30.0745 19.3292 29.8313 19.6292 29.8313H28.6937C28.9938 29.8313 29.237 30.0745 29.237 30.3746C29.237 30.6747 28.9937 30.9179 28.6937 30.9179Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 33.2021H19.6292C19.3292 33.2021 19.0859 32.9588 19.0859 32.6588C19.0859 32.3587 19.3292 32.1155 19.6292 32.1155H28.6937C28.9938 32.1155 29.237 32.3587 29.237 32.6588C29.237 32.9588 28.9937 33.2021 28.6937 33.2021Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 35.4857H19.6292C19.3292 35.4857 19.0859 35.2425 19.0859 34.9425C19.0859 34.6424 19.3292 34.3992 19.6292 34.3992H28.6937C28.9938 34.3992 29.237 34.6424 29.237 34.9425C29.237 35.2425 28.9937 35.4857 28.6937 35.4857Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 37.7697H19.6292C19.3292 37.7697 19.0859 37.5264 19.0859 37.2264C19.0859 36.9263 19.3292 36.6831 19.6292 36.6831H28.6937C28.9938 36.6831 29.237 36.9263 29.237 37.2264C29.237 37.5264 28.9937 37.7697 28.6937 37.7697Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 40.0536H19.6292C19.3292 40.0536 19.0859 39.8104 19.0859 39.5103C19.0859 39.2103 19.3292 38.967 19.6292 38.967H28.6937C28.9938 38.967 29.237 39.2103 29.237 39.5103C29.237 39.8104 28.9937 40.0536 28.6937 40.0536Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.6937 42.3376H19.6292C19.3292 42.3376 19.0859 42.0943 19.0859 41.7943C19.0859 41.4942 19.3292 41.251 19.6292 41.251H28.6937C28.9938 41.251 29.237 41.4942 29.237 41.7943C29.237 42.0943 28.9937 42.3376 28.6937 42.3376Z"
                    fill="#B4DBFF"
                />
            </g>
            <defs>
                <clipPath id="clip0_1360_35408">
                    <rect width="36" height="36" fill="white" transform="translate(14 14.7329)" />
                </clipPath>
            </defs>
        </svg>
    );
    const CustomIcon = (props) => <Icon component={TestSvg} {...props} />;

    return <CustomIcon />;
}

export default CustomExerciseLgIcon;
