import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Workbook } from "exceljs";

import { fixedNumber } from "./validation";
import dayjs from "dayjs";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const keyToHeader = {
    user_name: "Tên đăng nhập",
    student_name: "Họ và Tên",
    listening_score: "Nghe",
    writing_score: "Viết",
    speaking_score: "Nói",
    reading_score: "Đọc",
    student: "Tên học sinh",
    evaluation: "Điểm tổng",
    exerciseResult: "Điểm thành phần",
    startedAt: "Bắt đầu lúc",
    submittedAt: "Nộp bài lúc",
};

export const exportToCSV = (apiData, fileName) => {
    const header = [];
    Object.keys(apiData[0]).forEach((key) => {
        if (key !== "user_id") header.push(keyToHeader[key]);
    });
    const filteredData = apiData.map(({ user_id, ...rest }) => {
        return {
            ...rest,
            listening_score: fixedNumber(rest.listening_score),
            writing_score: fixedNumber(rest.writing_score),
            speaking_score: fixedNumber(rest.speaking_score),
            reading_score: fixedNumber(rest.reading_score),
        };
    });
    // const ws = XLSX.utils.json_to_sheet(apiData);
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, filteredData, { origin: "A2", skipHeader: true });
    const wb = { Sheets: { mark: ws }, SheetNames: ["mark"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportAssignmentMark = (data, fileName, assignmetName) => {
    const workbook = new Workbook();
    workbook.addWorksheet(assignmetName);
    const SHEET = workbook.getWorksheet(1);
    SHEET.columns = [
        { header: "Họ tên", key: "student", width: 32 },
        { header: "Điểm tổng", key: "evaluation", width: 12 },
        { header: "Điểm thành phần", key: "exerciseResult", width: 52 },
        { header: "Bắt đầu lúc", key: "startedAt", width: 28 },
        { header: "Nộp bài lúc", key: "submittedAt", width: 28 },
    ];

    SHEET.addRow({
        student: data[0].student,
        evaluation: data[0].evaluation,
        exerciseResult: data[0].exerciseResult
            ?.map(
                (r, index) =>
                    `Bài ${index + 1}: ${fixedNumber(r?.evaluation?.mark)} điểm. ( ${r?.evaluation?.numberCorrect} / ${r?.evaluation?.numberItem} câu đúng )`
            )
            ?.join("\n"),
        startedAt: data[0].startedAt,
        submittedAt: data[0].submittedAt,
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${fileName?.replaceAll(" ", "")}.xlsx`);
    });
};

export const exportAssignmentMarkByClass = (data, assignmetName = "data") => {
    const workbook = new Workbook();
    const sheetName = assignmetName?.replace(/[*?:\\/\[\] ]/g, "");
    workbook.addWorksheet(sheetName);
    const SHEET = workbook.getWorksheet(1);
    SHEET.columns = [
        { header: "Tài khoản", key: "username", width: 32 },
        { header: "Họ tên", key: "name", width: 32 },
        { header: "Điểm tổng", key: "evaluation", width: 12 },
        { header: "Điểm thành phần", key: "exerciseResult", width: 52 },
        { header: "Bắt đầu lúc", key: "startedAt", width: 28 },
        { header: "Nộp bài lúc", key: "submittedAt", width: 28 },
    ];

    data?.forEach((element) => {
        SHEET.addRow({
            username: element?.student?.username,
            name: element?.student?.name,
            evaluation: element.evaluation?.mark,
            exerciseResult: element.exerciseResult
                ? element.exerciseResult
                      ?.map(
                          (r, index) =>
                              `Bài ${index + 1}: ${fixedNumber(r?.evaluation?.mark)} điểm. ( ${r?.evaluation?.numberCorrect} / ${
                                  r?.evaluation?.numberItem
                              } câu đúng )`
                      )
                      ?.join("\n")
                : "--/--",
            startedAt: element.startedAt ? dayjs(element.startedAt).format("DD/MM/YYYY HH:mm") : "Chưa làm",
            submittedAt: element.submittedAt ? dayjs(element.submittedAt).format("DD/MM/YYYY HH:mm") : "Chưa nộp",
        });
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${assignmetName}.xlsx`);
    });
};
