import Icon from "@ant-design/icons";
import React from "react";

function CustomSchoolIcon(props) {
    const SvgPath = () => (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 7H10.75M8 11H10.75M8 15H10.75M14.25 7H17M14.25 11H17M14.25 15H17M20.5 21V6.2C20.5 5.0799 20.5 4.51984 20.282 4.09202C20.0903 3.71569 19.7843 3.40973 19.408 3.21799C18.9802 3 18.4201 3 17.3 3H7.7C6.57989 3 6.01984 3 5.59202 3.21799C5.21569 3.40973 4.90973 3.71569 4.71799 4.09202C4.5 4.51984 4.5 5.0799 4.5 6.2V21M22.5 21H2.5"
                stroke="#8C8984"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomSchoolIcon;
