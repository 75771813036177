import Icon from "@ant-design/icons";
import React from "react";

function CustomDownloadIcon(props) {
    const SvgPath = () => (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 17.5H3M15.5 9.16667L10.5 14.1667M10.5 14.1667L5.5 9.16667M10.5 14.1667V2.5"
                stroke="#1A1918"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomDownloadIcon;
