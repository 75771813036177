export const ExerciseModeEnums = {
    READY: "READY",
    DOING: "DOING",
    REVIEW: "REVIEW",
    HIDE: "HIDE",
};

export const UserEnums = {
    STUDENT: "ROLE_USER",
    TEACHER: "ROLE_TEACHER",
    HOME_TEACHER: "ROLE_HOME_TEACHER",
    ADMIN: "ROLE_ADMIN",
    PRINCIPAL: "ROLE_PRINCIPAL",
    SCHOOL_ADMIN: "ROLE_SCHOOL_ADMIN",
    GUEST: "GUEST",
};

export const ElementEnums = {
    CHOICE: "Choice",
    TEXTINPUT: "TextInput",
    TEXTAREA: "TextArea",
    VOICE: "Voice",
    MATCHING: "Matching",
};

export const TrackingEnums = {
    ONLINE: "ONLINE",
    DOING_TEST_ASSIGNMENT: "DOING_TEST_ASSIGNMENT",
};

export const AssignmentTypeEnums = {
    TEST: 'TEST',
    NORMAL: 'NORMAL',
    OFFLINE: 'TEST_OFFLINE',
    NOGRADE: 'NOGRADE'
}

export const isDoing = (status) => {
    return status === ExerciseModeEnums.DOING;
};
export const isReview = (status) => {
    return status === ExerciseModeEnums.REVIEW;
};

export const isStudent = (me) => {
    return me?.role === UserEnums.STUDENT;
};
export const isTeacher = (me) => {
    return me?.role === UserEnums.TEACHER || me?.role === UserEnums.HOME_TEACHER;
};
export const isAdmin = (me) => {
    return me?.role === UserEnums.ADMIN || me?.role === UserEnums.PRINCIPAL;
};
