import { HomeFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import notfound from '../../../assets/images/notfound.jpg'
import { t } from 'i18next';

function NotFound(props) {
    let navigate = useNavigate();

    return (
        <div className='flex__center__center flex__column' style={{
            height: '100%',
            background: '#fff'
        }}>
            <img style={{height: 300, width: 'auto'}} src={notfound} alt='notfound' />
            <Typography.Title level={5}>{t('notfound')}</Typography.Title>
            <br />
            <Button size='large' type='primary' icon={<HomeFilled />} onClick={() => navigate('/')}>HOME</Button>
        </div>
    );
}

export default NotFound;