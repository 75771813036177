import { Input, message, Modal, Typography } from "antd";
import React, { useContext, useState } from "react";
import { debounce } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { StoreContext } from "../../stores";
import Loading from "../Loading";
import { AAUTH } from "../../apis/auth";

function ModalUpdateAddress(props) {
    const { open, close } = props;
    const { setLoading, loading, setMe, me } = useContext(StoreContext);

    const [name, setName] = useState();

    const updateName = useMutation({
        mutationFn: (values) => {
            message.loading("Đang cập nhật thông tin ...");
            setLoading(true);
            return handleFetchApi(AAUTH.updateProfile({ data: { address: name } }));
        },
        onSuccess: (response) => {
            message.destroy();
            if (response?.status !== "S0000") {
                setLoading(false);
                message.error("Có lỗi xảy ra. Vui lòng thử lại sau!");
                return;
            }
            setMe({ ...me, ...response?.data });
            message.success("Cập nhật thành công!");
            close();
            setLoading(false);
            return;
        },
    });

    return (
        <Modal zIndex={1033} open={open} width={500} title="Chỉnh sửa địa chỉ" okText="LƯU" cancelText="HỦY" onCancel={close} onOk={updateName.mutate}>
            <Typography.Text>Địa chỉ của bạn</Typography.Text>
            <Input size="large" maxLength={32} onChange={debounce((e) => setName(e?.target?.value))} defaultValue={me?.address} />
            {loading && <Loading />}
        </Modal>
    );
}

export default ModalUpdateAddress;
