import React from "react";
import ReactDOM from "react-dom/client";
import "./index.less";
import "./configs/i18n";
import Views from "./views";
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./stores";
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const rootElement = document.getElementById("root");

try {
    if (rootElement) {
        const root = ReactDOM.createRoot(rootElement);
        root.render(
            <StoreProvider>
                <Views />
            </StoreProvider>
        );
    } else {
        console.error("Root element not found");
    }
} catch (error) {
    
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
