import React, { useContext, useMemo } from "react";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import { StoreContext } from "../../../stores";
import { Card, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomTestIcon from "../../../components/Icons/CustomTestIcon";
import CustomExerciseIcon from "../../../components/Icons/CustomExerciseIcon";
import { fixedNumber } from "../../../utils/validation";
import { AEDUCATION } from "../../../apis/education";

const StudentScore = ({ studentId }) => {
    const { selectedClass } = useContext(StoreContext);
    const { t } = useTranslation();

    const { data: studentScores, isLoading } = useQuery(
        [`student_score_${studentId}`, selectedClass?.courseId],
        async () => {
            const studentScore = await handleFetchApi(AEDUCATION.getStudentResultSumary({ courseId: selectedClass?.courseId, studentId }));
            return studentScore?.data?.content;
        },
        {
            staleTime: 180000,
            cacheTime: 180000,
            refetchOnWindowFocus: false,
            enabled: !!selectedClass?.courseId && !!studentId,
        }
    );

    const renderedScores = useMemo(
        () =>
            studentScores?.map((item = {}, index) => (
                <div className="card --padding flex__between__center gap" key={item.id || index} style={{ marginBottom: 16 }}>
                    <div className="flex__start__start gap">
                        {item?.assignment_type === "TEST" ? <CustomTestIcon /> : <CustomExerciseIcon />}
                        <div>
                            <Typography.Title level={5}>{item.assignment_name}</Typography.Title>
                            <p>
                                {t("started")}: {moment(item.startAt).format("DD/MM/YYYY HH:mm")}
                            </p>
                            <p>
                                {t("submitted")}: {moment(item.submittedAt).format("DD/MM/YYYY HH:mm")}
                            </p>
                        </div>
                    </div>
                    <div>
                        <Typography.Title className="text--right" level={4} type="warning">
                            {fixedNumber(item?.score)} / 10
                        </Typography.Title>
                    </div>
                </div>
            )),
        [studentScores, t]
    );

    if (isLoading) return <Loading />;

    return <div style={{ position: "relative" }}>{renderedScores?.length ? renderedScores : <NoData text={t("no_data")} />}</div>;
};

export default StudentScore;
