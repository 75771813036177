import Icon from "@ant-design/icons";
import React from "react";

function CustomLeftIcon(props) {
    const SvgPath = () => (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5 24.332L12.5 16.332L20.5 8.33203" stroke="#403E3C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomLeftIcon;
