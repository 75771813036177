export const handleNextPage = (setSelectedExercise, setPageView, pageView, pages) => {
    setSelectedExercise();
    switch (pageView[0].key) {
        case "cover":
            setPageView([
                {
                    key: pages[0].page_id,
                    index: 1,
                },
                {
                    key: pages[1].page_id,
                    index: 2,
                },
            ]);
            break;

        case "end":
            setPageView([
                {
                    key: "cover",
                    index: 0,
                },
            ]);
            break;

        default: {
            const leftPage = {
                key: pages[pageView[0]?.index + 1]?.page_id,
                index: pageView[0]?.index + 2,
            };
            const rightPage = {
                key: pages[pageView[1]?.index + 1]?.page_id,
                index: pageView[1]?.index + 2,
            };
            setPageView([leftPage, rightPage]);
            break;
        }
    }
};

export const handleNextSinglePage = (setSelectedExercise, setPageView, pageView, pages) => {
    setSelectedExercise();
    switch (pageView[0].key) {
        case "cover":
            setPageView([
                {
                    key: pages[0].page_id,
                    index: 1,
                },
            ]);
            break;

        case "end":
            setPageView([
                {
                    key: "cover",
                    index: 0,
                },
            ]);
            break;

        default: {
            const nextPage = {
                key: pages[pageView[0].index].page_id,
                index: pageView[0].index + 1,
            };
            setPageView([nextPage]);
            break;
        }
    }
};

export const handlePrevPage = (setSelectedExercise, setPageView, pageView, pages) => {
    setSelectedExercise();
    switch (pageView[0].key) {
        case "cover":
            break;

        case "end":
            break;

        default: {
            if (pageView[0].index === 1)
                setPageView([
                    {
                        key: "cover",
                        index: 0,
                    },
                ]);
            else {
                const leftPage = {
                    key: pages[pageView[0].index - 3].page_id,
                    index: pageView[0].index - 2,
                };
                const rightPage = {
                    key: pages[pageView[1].index - 3].page_id,
                    index: pageView[1].index - 2,
                };
                setPageView([leftPage, rightPage]);
            }
            break;
        }
    }
};

export const handlePrevSinglePage = (setSelectedExercise, setPageView, pageView, pages) => {
    setSelectedExercise();
    switch (pageView[0].key) {
        case "cover":
            break;

        case "end":
            break;

        default: {
            if (pageView[0].index === 1)
                setPageView([
                    {
                        key: "cover",
                        index: 0,
                    },
                ]);
            else {
                const newPage = {
                    key: pages[pageView[0].index - 2].page_id,
                    index: pageView[0].index - 1,
                };
                setPageView([newPage]);
            }
            break;
        }
    }
};

export const updateBookmark = ({ book_id, index }) => {
    let bookmarks = JSON.parse(localStorage.getItem("bookmarks")) ?? {};
    bookmarks = { ...bookmarks, [book_id]: index };
    localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
};
