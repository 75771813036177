import moment from "moment";

export const getWeekRange = () => {
    const from = moment().subtract(6, "d").format("YYYY-MM-DD");
    const to = moment().format("YYYY-MM-DD");
    return {
        from: from,
        to: to,
    };
};

export const getMonthRange = () => {
    const from = moment().subtract(30, "d").format("YYYY-MM-DD");
    const to = moment().format("YYYY-MM-DD");
    return {
        from: from,
        to: to,
    };
};
