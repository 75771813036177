import Icon from "@ant-design/icons";
import React from "react";

function CustomFileIcon(props) {
    const SvgPath = () => (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 3.10156V7.2321C14.5 7.79216 14.5 8.07218 14.609 8.28609C14.7049 8.47426 14.8578 8.62724 15.046 8.72311C15.2599 8.8321 15.5399 8.8321 16.1 8.8321H20.2305M16.5 13.832H8.5M16.5 17.832H8.5M10.5 9.83203H8.5M14.5 2.83203H9.3C7.61984 2.83203 6.77976 2.83203 6.13803 3.15901C5.57354 3.44663 5.1146 3.90557 4.82698 4.47006C4.5 5.1118 4.5 5.95187 4.5 7.63203V18.032C4.5 19.7122 4.5 20.5523 4.82698 21.194C5.1146 21.7585 5.57354 22.2174 6.13803 22.5051C6.77976 22.832 7.61984 22.832 9.3 22.832H15.7C17.3802 22.832 18.2202 22.832 18.862 22.5051C19.4265 22.2174 19.8854 21.7585 20.173 21.194C20.5 20.5523 20.5 19.7122 20.5 18.032V8.83203L14.5 2.83203Z"
                stroke="#8C8984"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomFileIcon;
