import React, { useState } from "react";
import { Table, Button, notification, Layout, Typography, Space, Modal, Segmented, Input } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { cacheQueryConfig, handleFetchApi } from "../../configs/apiRequest";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Loading from "../../components/Loading";
import Report from "../../components/Report"; // Import the Report component
import CustomTrashIcon from "../../components/Icons/CustomTrashIcon";
import { SearchOutlined } from "@ant-design/icons";
import UpdateDailyReport from "./components/UpdateDailyReport";
import CustomDangerIcon from "../../components/Icons/CustomDangerIcon";
import { AREPORT } from "../../apis/report";

const { Content } = Layout;

const AdminReport = () => {
    const { t } = useTranslation();
    const [modal, setModal] = useState({ open: false, reportData: null, adminComment: null, type: null });
    const [viewMode, setViewMode] = useState("UNCONFIRMED");

    const { data, refetch, isLoading } = useQuery(
        ["fetchReports"],
        async () => {
            const response = await handleFetchApi(AREPORT.search({ params: "" }));
            console.log("response::", response);

            // Sort the data by created_at
            const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            const groupedData = sortedData.reduce((acc, current) => {
                const key = current.is_confirmed ? "CONFIRMED" : "UNCONFIRMED";
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(current);
                return acc;
            }, {});
            return groupedData;
        },
        cacheQueryConfig({ time: 3 })
    );

    const closeModal = () => setModal((prev) => ({ ...prev, open: false, type: null }));

    const acceptReportMutation = useMutation({
        mutationFn: (reportId) => handleFetchApi(AREPORT.accept({ reportId })),
        onSuccess: () => {
            notification.success({ message: "Report accepted successfully!" });
            closeModal();
            refetch(); // Refetch the reports after accepting one
        },
        onError: () => {
            notification.error({ message: "Failed to accept the report." });
        },
    });

    const commentReportMutation = useMutation({
        mutationFn: (reportId) => handleFetchApi(AREPORT.comment({ reportId, data: { admin_comment: modal.adminComment } })),
        onSuccess: () => {
            notification.success({ message: "Comment successfully!" });
            closeModal();
            refetch(); // Refetch the reports after accepting one
        },
        onError: () => {
            notification.error({ message: "Failed to accept the report." });
        },
    });

    const deleteReportMutation = useMutation({
        mutationFn: (reportId) => handleFetchApi(AREPORT.delete({ reportId })),
        onSuccess: () => {
            notification.success({ message: "Report deleted successfully!" });
            refetch(); // Refetch the reports after deleting one
        },
        onError: () => {
            notification.error({ message: "Failed to delete the report." });
        },
    });

    const columns = [
        {
            title: t("date"),
            dataIndex: "date",
            key: "date",
            render: (date, record) => moment(record?.created_at).format("DD/MM/YYYY"),
        },
        {
            title: t("content"),
            dataIndex: "content",
            key: "content",
            render: (text) => `${text.substring(0, 30)}...`,
        },
        {
            title: t("discipline_status"),
            dataIndex: ["class_report_status", "discipline"],
            key: "discipline",
            render: (value) => t(value),
        },
        {
            title: t("study_status"),
            dataIndex: ["class_report_status", "study"],
            key: "study",
            render: (value) => t(value),
        },
        {
            title: t("teacher"),
            dataIndex: ["teacher", "name"],
            key: "teacher_name",
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search class"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                            Search
                        </Button>
                        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value, record) => record.teacher.name.toString().toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: t("class"),
            dataIndex: "school_class",
            key: "class_name",
            render: (value, record) => `Lớp ${value?.grade} ${value?.name?.replace("Lớp ", "")}`,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search class"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}></Button>
                        <Button icon={<CustomTrashIcon />} onClick={() => clearFilters()} size="small" style={{ width: 90 }}></Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value, record) => {
                const label = `Lớp ${record?.school_class?.grade} ${record?.school_class?.name?.replace("Lớp ", "")}`;
                return label.toLowerCase().includes(value.toLowerCase());
            },
        },
        {
            title: t("status"),
            dataIndex: "is_confirmed",
            key: "is_confirmed",
            render: (value) => <Typography.Text type={value ? "success" : "warning"}>{value ? "Đã xác nhận" : "Chờ xác nhận"}</Typography.Text>,
        },
        {
            title: "",
            key: "actions",
            render: (_, record) => (
                <Space>
                    {
                        <>
                            <Button size="small" type="link" onClick={() => setModal({ open: true, reportData: record, type: "VIEW" })}>
                                XEM
                            </Button>

                            <Button
                                size="small"
                                icon={<CustomTrashIcon />}
                                type="text"
                                danger
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Bạn có thực sự muốn xóa báo cáo này?",
                                        centered: true,
                                        icon: <CustomDangerIcon />,
                                        onOk: () => deleteReportMutation.mutate(record.id),
                                    });
                                }}
                            >
                                {t("delete")}
                            </Button>
                        </>
                    }
                </Space>
            ),
        },
    ];

    const handleComment = (comment) => {
        setModal((prev) => ({ ...prev, adminComment: comment }));
    };

    return (
        <Content>
            <div className="page admin_report">
                <Typography.Title level={4} className="flex__between__center">
                    Báo cáo hàng ngày{" "}
                    <Segmented
                        onChange={(value) => setViewMode(value)}
                        size="large"
                        options={[
                            {
                                label: "Chưa duyệt",
                                value: "UNCONFIRMED",
                            },
                            {
                                label: "Đã duyệt",
                                value: "CONFIRMED",
                            },
                        ]}
                    />
                </Typography.Title>
                {isLoading ? <Loading /> : <Table dataSource={data && data[viewMode]} columns={columns} rowKey="id" />}

                <Modal
                    centered={true}
                    title="Chi tiết báo cáo"
                    open={modal.type === "VIEW"}
                    onCancel={() => setModal({ open: false, reportData: null })}
                    footer={null}
                    width="90dvw"
                >
                    {modal.reportData && <Report onComment={handleComment} reportData={modal.reportData} />}
                    {!modal?.reportData?.is_confirmed && (
                        <div className="flex__end__center gap">
                            <Button type="primary" size="large" onClick={() => setModal((prev) => ({ ...prev, type: "UPDATE" }))}>
                                {t("update")}
                            </Button>
                            <Button type="primary" size="large" onClick={() => commentReportMutation.mutate(modal.reportData.id)}>
                                Gửi nhận xét
                            </Button>
                            <Button type="primary" size="large" className="btn-success" onClick={() => acceptReportMutation.mutate(modal.reportData.id)}>
                                Duyệt báo cáo
                            </Button>
                        </div>
                    )}
                </Modal>
            </div>
            <UpdateDailyReport initData={modal?.reportData} open={modal?.type === "UPDATE"} close={closeModal} />
        </Content>
    );
};

export default AdminReport;
