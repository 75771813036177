export const ABOOK = {
    getAllInfo: ({ bookId }) => ({
        method: "GET",
        url: `/books/${bookId}/all-info`,
    }),
    itemLookUpByParentId: ({ parentId }) => ({
        method: "GET",
        url: `items/look-up?parent_item_id=${parentId}`,
    }),
    getChapters: ({ bookId }) => ({
        method: "GET",
        url: `/books/${bookId}/chapters`,
    }),
    getPagesByIds: ({ params }) => ({
        method: "GET",
        url: `/pages/batch?ids=${params}`,
    }),
    getAccessCode: ({ userId }) => ({
        method: "GET",
        url: `admin/book-codes/look-up/access-code/users/${userId}?size=999&page=0`,
    }),
    getExams: ({ bookId }) => ({
        method: "GET",
        url: `/items/exams?book_id=${bookId}&page=0&size=20`,
    }),
    getExercises: ({ bookId }) => ({
        method: "GET",
        url: `/items/look-up?type=Exercise&book_id=${bookId}&size=100&page=0`,
    }),
};
