import Icon from "@ant-design/icons";
import React from "react";

function CustomBookIcon(props) {
    const TestSvg = () => (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.0102 30.298H5.24341C3.6296 30.298 2.32129 28.9873 2.32129 27.3704C2.32129 25.7536 3.62954 24.4429 5.24341 24.4429H30.0102C28.7196 26.1804 28.7196 28.5604 30.0102 30.298Z"
                fill="#FFF7E8"
            />
            <path
                d="M30.8331 32.6949H5.3145C2.38406 32.6949 0 30.3064 0 27.3705C0 24.4346 2.38406 22.0461 5.3145 22.0461H30.8331C31.4776 22.0461 32 22.5696 32 23.2153C32 23.8609 31.4776 24.3844 30.8331 24.3844H5.3145C3.67094 24.3844 2.33387 25.724 2.33387 27.3706C2.33387 29.0171 3.671 30.3568 5.3145 30.3568H30.8331C31.4776 30.3568 32 30.8802 32 31.5259C32 32.1715 31.4776 32.6949 30.8331 32.6949Z"
                fill="#FF4B6F"
            />
            <path
                d="M30.0102 8.94668H5.24341C3.6296 8.94668 2.32129 7.63599 2.32129 6.01912C2.32129 4.4023 3.62954 3.09155 5.24341 3.09155H30.0102C28.7196 4.82912 28.7196 7.20912 30.0102 8.94668Z"
                fill="#FFF7E8"
            />
            <path
                d="M30.8331 11.3436H5.3145C2.38406 11.3436 0 8.95507 0 6.0192C0 3.08332 2.38406 0.694824 5.3145 0.694824H30.8331C31.4776 0.694824 32 1.21826 32 1.86395C32 2.50964 31.4776 3.03307 30.8331 3.03307H5.3145C3.67094 3.03307 2.33387 4.3727 2.33387 6.01926C2.33387 7.66582 3.671 9.00545 5.3145 9.00545H30.8331C31.4776 9.00545 32 9.52889 32 10.1746C32 10.8201 31.4776 11.3436 30.8331 11.3436Z"
                fill="#FF802C"
            />
            <path
                d="M1.98975 13.7939H26.7566C28.3704 13.7939 29.6787 15.1046 29.6787 16.7215C29.6787 18.3384 28.3704 19.6491 26.7566 19.6491H1.98975C3.28037 17.9115 3.28037 15.5315 1.98975 13.7939Z"
                fill="#FFF7E8"
            />
            <path
                d="M1.16694 11.3972H26.6855C29.6159 11.3972 32 13.7857 32 16.7216C32 19.6575 29.6159 22.046 26.6855 22.046H1.16694C0.522437 22.046 0 21.5225 0 20.8768C0 20.2312 0.522437 19.7077 1.16694 19.7077H26.6855C28.3291 19.7077 29.6661 18.3681 29.6661 16.7215C29.6661 15.075 28.329 13.7353 26.6855 13.7353H1.16694C0.522437 13.7354 0 13.212 0 12.5663C0 11.9207 0.522437 11.3972 1.16694 11.3972Z"
                fill="#227DBF"
            />
            <path
                d="M26.6853 11.3972H24.1099C26.4581 11.3972 28.4541 12.9315 29.1551 15.0516C29.4775 15.5286 29.666 16.1034 29.666 16.7216C29.666 17.3397 29.4775 17.9146 29.1551 18.3916C28.4541 20.5117 26.4581 22.046 24.1099 22.046H26.6853C29.6157 22.046 31.9998 19.6575 31.9998 16.7216C31.9998 13.7857 29.6157 11.3972 26.6853 11.3972Z"
                fill="#005592"
            />
            <path d="M19.8042 16.7217H24.0456V26.7307L21.9621 24.7179L19.8042 26.7307V16.7217Z" fill="#FFC456" />
        </svg>
    );
    const CustomIcon = (props) => <Icon component={TestSvg} {...props} />;

    return <CustomIcon />;
}

export default CustomBookIcon;
