import Icon from "@ant-design/icons";
import React from "react";

function CustomClassIconOutline(props) {
    const SvgPath = () => (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.5 10.0001V16.0112C5.5 16.3702 5.5 16.5496 5.55465 16.7081C5.60299 16.8482 5.68187 16.9759 5.78558 17.0817C5.90287 17.2015 6.0634 17.2818 6.38446 17.4423L11.7845 20.1423C12.0468 20.2735 12.178 20.3391 12.3156 20.3649C12.4375 20.3877 12.5625 20.3877 12.6844 20.3649C12.822 20.3391 12.9532 20.2735 13.2155 20.1423L18.6155 17.4423C18.9366 17.2818 19.0971 17.2015 19.2144 17.0817C19.3181 16.9759 19.397 16.8482 19.4453 16.7081C19.5 16.5496 19.5 16.3702 19.5 16.0112V10.0001M2.5 8.50006L12.1422 3.67895C12.2734 3.61336 12.339 3.58056 12.4078 3.56766C12.4687 3.55622 12.5313 3.55622 12.5922 3.56766C12.661 3.58056 12.7266 3.61336 12.8578 3.67895L22.5 8.50006L12.8578 13.3212C12.7266 13.3868 12.661 13.4196 12.5922 13.4325C12.5313 13.4439 12.4687 13.4439 12.4078 13.4325C12.339 13.4196 12.2734 13.3868 12.1422 13.3212L2.5 8.50006Z"
                stroke="#8C8984"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomClassIconOutline;
