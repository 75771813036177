import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../stores";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ButtonExit({ size = "middle", callback = "/", onExit = () => {} }) {
    const { setMedia } = useContext(StoreContext);

    return (
        <Button
            size={size}
            onClick={() => {
                setMedia();
                onExit()
            }}
            icon={<CloseOutlined />}
            type="primary"
            danger
        ></Button>
    );
}

export default ButtonExit;
