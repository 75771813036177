import Icon from "@ant-design/icons";
import React from "react";

function CustomHomeIcon(props) {
    const SvgPath = () => (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1057_71588)">
                <path d="M16.0004 5.7998L6.45898 15.0809V28.7149H25.5417V15.0809L16.0004 5.7998Z" fill="white" />
                <path d="M21.8486 5.16968V8.63002L24.9039 11.602V5.16968H21.8486Z" fill="#D88E00" />
                <path
                    d="M23.618 8.86514C23.4635 8.71482 23.3763 8.50836 23.3763 8.29273V5.16968H21.8486V8.63002L24.9039 11.602V10.116L23.618 8.86514Z"
                    fill="#BC7B00"
                />
                <path
                    d="M28.0734 14.5201L16.7976 3.55175C16.3539 3.12012 15.6474 3.12012 15.2038 3.55175L3.92804 14.5201C3.47557 14.9602 3.46556 15.6837 3.9057 16.1362C4.34584 16.5887 5.06943 16.5987 5.52184 16.1585L15.8439 6.11798C15.9312 6.03307 16.0702 6.03307 16.1575 6.11798L26.4796 16.1585C26.9323 16.5988 27.6558 16.5885 28.0957 16.1362C28.5359 15.6837 28.5258 14.9602 28.0734 14.5201Z"
                    fill="#FF515C"
                />
                <path
                    d="M15.8439 6.11798C15.8831 6.07984 15.9328 6.0598 15.9835 6.05589L16.9882 5.07859C17.2849 4.78994 17.6989 4.69539 18.0735 4.79284L16.7976 3.55175C16.3539 3.12012 15.6474 3.12012 15.2038 3.55175L3.92804 14.5201C3.47557 14.9602 3.46556 15.6837 3.9057 16.1362C4.34584 16.5887 5.06943 16.5987 5.52184 16.1585L15.8439 6.11798Z"
                    fill="#E51828"
                />
                <path
                    d="M25.7898 4.93615V3.62713C25.7898 3.40674 25.6111 3.22803 25.3907 3.22803H21.361C21.1406 3.22803 20.9619 3.40669 20.9619 3.62713V4.93621C20.9619 5.1566 21.1406 5.33531 21.361 5.33531H25.3907C25.6111 5.33526 25.7898 5.1566 25.7898 4.93615Z"
                    fill="#D88E00"
                />
                <path
                    d="M22.4896 4.93615V3.62713C22.4896 3.40674 22.6682 3.22803 22.8886 3.22803H21.361C21.1406 3.22803 20.9619 3.40669 20.9619 3.62713V4.93621C20.9619 5.1566 21.1406 5.33531 21.361 5.33531H22.8886C22.6682 5.33526 22.4896 5.1566 22.4896 4.93615Z"
                    fill="#BC7B00"
                />
                <path d="M8.66699 18.3813H15.0673V28.7149H8.66699V18.3813Z" fill="#BC7B00" />
                <path
                    d="M16.9346 18.4365V26.1645C16.9346 26.3349 17.0727 26.4731 17.2432 26.4731H23.0263C23.1967 26.4731 23.3349 26.3349 23.3349 26.1645V18.4365H16.9346Z"
                    fill="#60B8FE"
                />
                <path
                    d="M19.0074 26.1645V18.4365H16.9346V26.1645C16.9346 26.3349 17.0727 26.4731 17.2432 26.4731H19.316C19.1455 26.4731 19.0074 26.3349 19.0074 26.1645Z"
                    fill="#23A8FE"
                />
                <path
                    d="M20.5486 18.4917C20.5486 18.473 20.5469 18.4546 20.5445 18.4365H19.7249C19.7225 18.4546 19.7208 18.473 19.7208 18.4917V22.0685H16.9346V22.8963H19.7208V26.473H20.5487V22.8963H23.3349V22.0685H20.5487L20.5486 18.4917Z"
                    fill="#8BCAFF"
                />
                <path d="M16.9346 22.0686H19.0074V22.8964H16.9346V22.0686Z" fill="#60B8FE" />
                <path
                    d="M23.0263 16.4822H17.2432C17.0727 16.4822 16.9346 16.6203 16.9346 16.7908V18.4917H23.3349V16.7908C23.3349 16.6204 23.1967 16.4822 23.0263 16.4822Z"
                    fill="#23A8FE"
                />
                <path
                    d="M19.316 16.4822H17.2432C17.0727 16.4822 16.9346 16.6203 16.9346 16.7908V18.4917H19.0074V16.7908C19.0074 16.6204 19.1455 16.4822 19.316 16.4822Z"
                    fill="#0193FA"
                />
                <path
                    d="M14.7587 16.4822H8.97562C8.80521 16.4822 8.66699 16.6203 8.66699 16.7908V18.4917H15.0673V16.7908C15.0673 16.6204 14.9291 16.4822 14.7587 16.4822Z"
                    fill="#BC7B00"
                />
                <path
                    d="M12.8364 23.7683C13.294 23.7683 13.665 23.3973 13.665 22.9397C13.665 22.4821 13.294 22.1111 12.8364 22.1111C12.3788 22.1111 12.0078 22.4821 12.0078 22.9397C12.0078 23.3973 12.3788 23.7683 12.8364 23.7683Z"
                    fill="#DFEBFA"
                />
                <path
                    d="M25.9448 28.5493H6.05628C5.83589 28.5493 5.65723 28.728 5.65723 28.9484V30.2575C5.65723 30.4779 5.83589 30.6566 6.05628 30.6566H25.9448C26.1652 30.6566 26.3439 30.4779 26.3439 30.2575V28.9484C26.3439 28.728 26.1652 28.5493 25.9448 28.5493Z"
                    fill="#D88E00"
                />
                <path
                    d="M8.18205 30.2575V28.9484C8.18205 28.728 8.36071 28.5493 8.5811 28.5493H6.05628C5.83589 28.5493 5.65723 28.728 5.65723 28.9484V30.2575C5.65723 30.4779 5.83589 30.6566 6.05628 30.6566H8.58115C8.36071 30.6566 8.18205 30.4779 8.18205 30.2575Z"
                    fill="#BC7B00"
                />
            </g>
            <defs>
                <clipPath id="clip0_1057_71588">
                    <rect width="27.4286" height="27.4286" fill="white" transform="translate(2.28613 3.22803)" />
                </clipPath>
            </defs>
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomHomeIcon;
