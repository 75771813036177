import Icon from "@ant-design/icons";
import React from "react";

function CustomSearchIcon(props) {
    const SvgPath = () => (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 21.6948L16.65 17.3448M19 11.6948C19 16.1131 15.4183 19.6948 11 19.6948C6.58172 19.6948 3 16.1131 3 11.6948C3 7.27655 6.58172 3.69482 11 3.69482C15.4183 3.69482 19 7.27655 19 11.6948Z"
                stroke="#666460"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomSearchIcon;
