import { Drawer, Spin, Typography } from "antd";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { AEDUCATION } from "../../../apis/education";

function SchoolDetail({ open, data, close }) {
    const { data: classes, isLoading: loadingClasses } = useQuery(
        ["classes", data?.id],
        () => handleFetchApi(AEDUCATION.getClassesBySchool({ schoolId: data?.id })),
        {
            enabled: !!data?.id,
        }
    );

    const { data: teachers, isLoading: loadingTeachers } = useQuery(
        ["teachers", data?.id],
        () => handleFetchApi(AEDUCATION.getTeachersBySchool({ schoolId: data?.id })),
        {
            enabled: !!data?.id,
        }
    );

    const loading = loadingClasses || loadingTeachers;

    return (
        <Drawer onClose={close} width={"50dvw"} open={open} title={data?.name}>
            <Typography.Text>{data?.address}</Typography.Text>
            <h3>Classes</h3>
            {loading ? (
                <Spin />
            ) : (
                <div className="flex__between__center gap-sm flex__wrap">
                    {classes?.data?.map((cls) => (
                        <div key={cls.id} className="card --padding --small" style={{ width: "48%" }}>
                            <Typography.Text strong>
                                Lớp {cls?.grade} {cls.name}
                            </Typography.Text>{" "}
                            <br />
                            <Typography.Text type="secondary">Giáo viên: {cls?.teacher?.name || "Chưa có thông tin"}</Typography.Text>
                        </div>
                    ))}
                    <h3>Teachers</h3>
                    {teachers?.data?.map((teacher) => (
                        <div key={teacher.id} className="card">
                            {teacher.name}
                        </div>
                    ))}
                </div>
            )}
        </Drawer>
    );
}

export default SchoolDetail;
