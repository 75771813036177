import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { Popover, Badge, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { LoadingOutlined } from "@ant-design/icons";
import NoData from "../NoData";
import moment from "moment";
import avatarImg from "../../assets/images/avatar.png";
import testSvg from "../../assets/images/test.png";
import CustomBellIcon from "../Icons/CustomBellIcon";
import { debounce } from "../../utils";
import { ANOTIFICATION } from "../../apis/notification";

require("moment/locale/vi");

function ButtonNotification(props) {
    const [page, setPage] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [isViewAll, setIsViewAll] = useState(false);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const NotificationConfigs = {
        STUDENT_COMPLETED_ASSIGNMENT: {
            image: avatarImg,
            action: (data) => navigate(data),
        },
        CREATED_TEST_ASSIGNMENT: {
            image: avatarImg,
            action: (link) => {
                navigate(link);
            },
        },
        CREATED_NORMAL_ASSIGNMENT: {
            image: avatarImg,
            action: (link) => {
                navigate(link);
            },
        },
        DEADLINE_IS_COMING_TEST_ASSIGNMENT: {
            image: testSvg,
            action: (link) => {
                navigate(link);
            },
        },
    };

    const {
        data: notifies,
        isFetching,
        refetch,
    } = useQuery(
        ["notifications"],
        async () => {
            const notis = await handleFetchApi(ANOTIFICATION.getNoti({ page: 0 }));
            var ids = new Set(notis?.data?.map((d) => d.id));
            var merged = [...notis?.data, ...notifications?.filter((d) => !ids.has(d.id))];
            setNotifications(merged);
            return merged;
        },
        {
            // keepPreviousData: false,
            refetchOnWindowFocus: true,
            refetchInterval: 1000 * 60, // 1 minute
        }
    );

    const getMoreNoti = useMutation({
        mutationFn: async (page) => {
            setLoading(true);
            const moreNoti = await handleFetchApi(ANOTIFICATION.getNoti({ page }));
            return moreNoti?.data;
        },
        onSuccess: (response) => {
            if (!response?.length || response?.length < 10) {
                setIsViewAll(true);
                setLoading(false);
                return;
            }
            const newList = notifications;
            newList.push(...response);
            setNotifications(newList);
            setLoading(false);
        },
    });

    const onScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !isViewAll) {
            setPage(page + 1);
            getMoreNoti.mutate(page + 1);
        }
    };

    const readAll = useMutation({
        mutationFn: (notificationId) => {
            return handleFetchApi(ANOTIFICATION.readAll());
        },
        onSuccess: async (response, variables) => {
            refetch();
            return;
        },
    });

    const getMessageByCurrentLanguage = ({ noti }) => {
        let currentLang = localStorage.getItem("i18nextLng") || "vn";
        if (currentLang === "en") return noti?.messageEng;
        return noti?.messageVni;
    };

    const renderListNotifies = () => {
        return (
            notifications &&
            notifications.map((noti = {}, index) => (
                <div
                    className={`noti flex__start__start gap-sm ${noti?.read ? "__read" : "__unread"}`}
                    key={noti?.id ?? index}
                    onClick={() => {
                        if (!noti?.read) handleFetchApi(ANOTIFICATION.readOnly({id: noti?.id}));
                        NotificationConfigs[noti.metaData?.sectionType]?.action(noti.metaData?.link);
                    }}
                >
                    <img alt="avatarImg" src={NotificationConfigs[noti.metaData?.sectionType]?.image || avatarImg} />
                    <div className="flex__start__start flex__column">
                        <Typography.Text strong>{noti?.metaData?.actorName}</Typography.Text>
                        <Typography.Text>{getMessageByCurrentLanguage({ noti }) ?? "No message"}</Typography.Text>
                        <Typography.Text type="secondary" className="noti__fromnow">
                            {moment(noti.created_at).fromNow()}
                        </Typography.Text>
                    </div>
                </div>
            ))
        );
    };

    const getUnreadNoti = () => {
        return notifications?.filter((n) => n.read === false)?.length;
    };

    return (
        <Popover
            trigger="click"
            placement="bottomLeft"
            overlayClassName="popover_noti"
            title={
                <div className="flex__between__center">
                    <Typography.Text>{t("notification")}</Typography.Text>
                    <Button type="link" onClick={readAll.mutate}>
                        {t(getUnreadNoti() ? "read_all" : "readed_all")}
                    </Button>
                </div>
            }
            content={
                notifications?.length ? (
                    <div onScroll={debounce(onScroll, 200)} className="notifications flex__start__start flex__column">
                        {isFetching && (
                            <Typography.Text type="danger" className="text--center">
                                <LoadingOutlined /> Đang tải thông báo mới . . .
                            </Typography.Text>
                        )}
                        {renderListNotifies()}
                        {isViewAll && (
                            <Typography.Text type="secondary" className="text--center">
                                {t("view_all_message")}
                            </Typography.Text>
                        )}
                        {loading && (
                            <Typography.Text type="danger" className="text--center">
                                <LoadingOutlined /> Đang tải thông báo mới . . .
                            </Typography.Text>
                        )}
                    </div>
                ) : (
                    <NoData imageWidth={80} text={t("no_message")} />
                )
            }
        >
            <Button className="btn-white" size="large">
                <Badge count={getUnreadNoti() ?? 0}>
                    <CustomBellIcon />
                </Badge>
            </Button>
        </Popover>
    );
}

export default ButtonNotification;
