import Icon from "@ant-design/icons";
import React from "react";

function CustomUserCheckIcon(props) {
    const SvgPath = () => (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5 21.832V20.632C16.5 18.9519 16.5 18.1118 16.173 17.4701C15.8854 16.9056 15.4265 16.4466 14.862 16.159C14.2202 15.832 13.3802 15.832 11.7 15.832H7.3C5.61984 15.832 4.77976 15.832 4.13803 16.159C3.57354 16.4466 3.1146 16.9056 2.82698 17.4701C2.5 18.1118 2.5 18.9519 2.5 20.632V21.832M16.5 6.83203L18.5 8.83203L22.5 4.83203M13 8.33203C13 10.265 11.433 11.832 9.5 11.832C7.567 11.832 6 10.265 6 8.33203C6 6.39903 7.567 4.83203 9.5 4.83203C11.433 4.83203 13 6.39903 13 8.33203Z"
                stroke="#8C8984"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomUserCheckIcon;
