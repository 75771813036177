import Icon from "@ant-design/icons";
import React from "react";

function CustomExerciseIcon(props) {
    const TestSvg = () => (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#FFE2E2" />
            <rect width="40" height="40" rx="20" fill="#BBDAFF" />
            <g clipPath="url(#clip0_732_58956)">
                <path
                    d="M27.2945 8.33325H26.5812C26.029 8.33325 25.5771 8.78505 25.5771 9.33729V10.8341H28.2986V9.33729C28.2986 8.78505 27.8468 8.33325 27.2945 8.33325Z"
                    fill="#3C8AFF"
                />
                <path
                    d="M27.6182 8.38815C27.5164 8.35314 27.4078 8.33325 27.2945 8.33325H26.5812C26.029 8.33325 25.5771 8.78505 25.5771 9.33729V10.8341H26.9379V9.33729C26.9379 8.89834 27.2237 8.52373 27.6182 8.38815Z"
                    fill="#3B8AFF"
                />
                <path
                    d="M30.1081 14.2288H9.89216C9.02075 14.4701 8.37598 15.2718 8.37598 16.2173V28.2396C8.37598 29.3743 9.30437 30.3027 10.4391 30.3027H18.0635C18.3211 30.3027 18.5443 30.469 18.6324 30.7111C18.8354 31.2685 19.3698 31.6666 19.9975 31.6666C20.6251 31.6666 21.1595 31.2685 21.3625 30.7111C21.4507 30.469 21.6738 30.3027 21.9314 30.3027H29.5612C30.6959 30.3027 31.6243 29.3742 31.6243 28.2396V16.2173C31.6243 15.2718 30.9795 14.4701 30.1081 14.2288Z"
                    fill="#3C8AFF"
                />
                <path
                    d="M10.1667 28.2396V16.2173C10.1667 15.2718 10.8115 14.4701 11.6829 14.2288H9.89216C9.02075 14.4701 8.37598 15.2718 8.37598 16.2173V28.2396C8.37598 29.3743 9.30437 30.3027 10.4391 30.3027H12.2299C11.0951 30.3027 10.1667 29.3742 10.1667 28.2396Z"
                    fill="#0057DA"
                />
                <path
                    d="M20.4231 30.7112C20.335 30.4691 20.1118 30.3027 19.8542 30.3027H18.0635C18.3211 30.3027 18.5442 30.4691 18.6323 30.7112C18.8353 31.2686 19.3698 31.6666 19.9974 31.6666C20.3355 31.6666 20.646 31.5503 20.8928 31.3566C20.6815 31.1907 20.5167 30.9683 20.4231 30.7112Z"
                    fill="#0057DA"
                />
                <path
                    d="M29.2178 12.7822C28.9931 12.7822 21.0631 12.7822 20.8412 12.7822C20.3494 12.7822 19.9507 13.1809 19.9507 13.6727V27.8708C19.9507 28.3626 20.3494 28.7613 20.8412 28.7613H29.2178C29.7096 28.7613 30.1083 28.3626 30.1083 27.8708V13.6727C30.1083 13.1809 29.7096 12.7822 29.2178 12.7822Z"
                    fill="#F4F9FF"
                />
                <path
                    d="M21.7414 27.8708V13.6727C21.7414 13.1809 22.1401 12.7822 22.6319 12.7822C21.6321 12.7822 20.9061 12.7822 20.8412 12.7822C20.3494 12.7822 19.9507 13.1809 19.9507 13.6727V27.8708C19.9507 28.3626 20.3494 28.7613 20.8412 28.7613H22.6319C22.1401 28.7613 21.7414 28.3626 21.7414 27.8708Z"
                    fill="#E4F0FF"
                />
                <path
                    d="M24.2214 15.863H22.1431C21.9487 15.863 21.791 15.7053 21.791 15.5108C21.791 15.3163 21.9487 15.1587 22.1431 15.1587H24.2214C24.4159 15.1587 24.5736 15.3163 24.5736 15.5108C24.5736 15.7053 24.4159 15.863 24.2214 15.863Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M24.2214 17.3432H22.1431C21.9487 17.3432 21.791 17.1855 21.791 16.991C21.791 16.7966 21.9487 16.6389 22.1431 16.6389H24.2214C24.4159 16.6389 24.5736 16.7966 24.5736 16.991C24.5736 17.1855 24.4159 17.3432 24.2214 17.3432Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M24.2214 18.8236H22.1431C21.9487 18.8236 21.791 18.666 21.791 18.4715C21.791 18.277 21.9487 18.1194 22.1431 18.1194H24.2214C24.4159 18.1194 24.5736 18.277 24.5736 18.4715C24.5736 18.666 24.4159 18.8236 24.2214 18.8236Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M24.2214 20.3039H22.1431C21.9487 20.3039 21.791 20.1462 21.791 19.9517C21.791 19.7573 21.9487 19.5996 22.1431 19.5996H24.2214C24.4159 19.5996 24.5736 19.7573 24.5736 19.9517C24.5736 20.1462 24.4159 20.3039 24.2214 20.3039Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M24.2214 21.7843H22.1431C21.9487 21.7843 21.791 21.6267 21.791 21.4322C21.791 21.2377 21.9487 21.0801 22.1431 21.0801H24.2214C24.4159 21.0801 24.5736 21.2377 24.5736 21.4322C24.5736 21.6267 24.4159 21.7843 24.2214 21.7843Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M24.9993 23.2646H22.1431C21.9487 23.2646 21.791 23.1069 21.791 22.9124C21.791 22.718 21.9487 22.5603 22.1431 22.5603H24.9993C25.1938 22.5603 25.3515 22.718 25.3515 22.9124C25.3515 23.1069 25.1938 23.2646 24.9993 23.2646Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.0183 24.7448H22.1431C21.9487 24.7448 21.791 24.5871 21.791 24.3927C21.791 24.1982 21.9487 24.0405 22.1431 24.0405H28.0183C28.2128 24.0405 28.3704 24.1982 28.3704 24.3927C28.3704 24.5871 28.2128 24.7448 28.0183 24.7448Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M28.0183 26.2253H22.1431C21.9487 26.2253 21.791 26.0676 21.791 25.8731C21.791 25.6786 21.9487 25.521 22.1431 25.521H28.0183C28.2128 25.521 28.3704 25.6786 28.3704 25.8731C28.3704 26.0676 28.2128 26.2253 28.0183 26.2253Z"
                    fill="#B4DBFF"
                />
                <path d="M28.2984 20.2706L26.9377 20.3645L26.7969 11.8398H28.2984V20.2706Z" fill="#90CAFF" />
                <path d="M26.9379 20.3645L25.5771 20.2706V11.8398H26.9379V20.3645Z" fill="#3B8AFF" />
                <path d="M25.5771 10.7402H28.2986V11.9935H25.5771V10.7402Z" fill="#DFEBFA" />
                <path d="M25.5771 10.7402H26.9379V11.9935H25.5771V10.7402Z" fill="#B4DBFF" />
                <path d="M27.7626 21.5179L28.2986 20.2708H25.5771L26.1131 21.5179H27.7626Z" fill="#B4DBFF" />
                <path d="M25.5771 20.2708L26.1131 21.5179H27.4738L26.9379 20.2708H25.5771Z" fill="#8BCAFF" />
                <path d="M26.0928 21.4709L26.6489 22.7651C26.7579 23.0188 27.1175 23.0188 27.2265 22.7651L27.7826 21.4709H26.0928Z" fill="#685E68" />
                <path
                    d="M27.4535 21.4709H26.0928L26.6489 22.7651C26.7579 23.0187 27.1175 23.0187 27.2265 22.7651L27.618 21.854L27.4535 21.4709Z"
                    fill="#554E56"
                />
                <path
                    d="M19.1069 12.7822H10.7826C10.2908 12.7822 9.89209 13.1809 9.89209 13.6727V27.8708C9.89209 28.3626 10.2908 28.7613 10.7826 28.7613H19.1069C19.5987 28.7613 19.9974 28.3626 19.9974 27.8708V13.6727C19.9974 13.1809 19.5987 12.7822 19.1069 12.7822Z"
                    fill="#F4F9FF"
                />
                <path
                    d="M11.6828 27.8708V13.6727C11.6828 13.1809 12.0815 12.7822 12.5733 12.7822H10.7826C10.2908 12.7822 9.89209 13.1809 9.89209 13.6727V27.8708C9.89209 28.3626 10.2908 28.7613 10.7826 28.7613H12.5733C12.0815 28.7613 11.6828 28.3626 11.6828 27.8708Z"
                    fill="#E4F0FF"
                />
                <path
                    d="M17.8571 15.863H11.982C11.7875 15.863 11.6299 15.7053 11.6299 15.5108C11.6299 15.3163 11.7875 15.1587 11.982 15.1587H17.8572C18.0516 15.1587 18.2093 15.3163 18.2093 15.5108C18.2093 15.7053 18.0516 15.863 17.8571 15.863Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 17.3432H11.982C11.7875 17.3432 11.6299 17.1855 11.6299 16.991C11.6299 16.7966 11.7875 16.6389 11.982 16.6389H17.8572C18.0516 16.6389 18.2093 16.7966 18.2093 16.991C18.2093 17.1855 18.0516 17.3432 17.8571 17.3432Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 18.8236H11.982C11.7875 18.8236 11.6299 18.666 11.6299 18.4715C11.6299 18.277 11.7875 18.1194 11.982 18.1194H17.8572C18.0516 18.1194 18.2093 18.277 18.2093 18.4715C18.2093 18.666 18.0516 18.8236 17.8571 18.8236Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 20.3039H11.982C11.7875 20.3039 11.6299 20.1462 11.6299 19.9517C11.6299 19.7573 11.7875 19.5996 11.982 19.5996H17.8572C18.0516 19.5996 18.2093 19.7573 18.2093 19.9517C18.2093 20.1462 18.0516 20.3039 17.8571 20.3039Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 21.7843H11.982C11.7875 21.7843 11.6299 21.6267 11.6299 21.4322C11.6299 21.2377 11.7875 21.0801 11.982 21.0801H17.8572C18.0516 21.0801 18.2093 21.2377 18.2093 21.4322C18.2093 21.6267 18.0516 21.7843 17.8571 21.7843Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 23.2646H11.982C11.7875 23.2646 11.6299 23.1069 11.6299 22.9124C11.6299 22.718 11.7875 22.5603 11.982 22.5603H17.8572C18.0516 22.5603 18.2093 22.718 18.2093 22.9124C18.2093 23.1069 18.0516 23.2646 17.8571 23.2646Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 24.7448H11.982C11.7875 24.7448 11.6299 24.5871 11.6299 24.3927C11.6299 24.1982 11.7875 24.0405 11.982 24.0405H17.8572C18.0516 24.0405 18.2093 24.1982 18.2093 24.3927C18.2093 24.5871 18.0516 24.7448 17.8571 24.7448Z"
                    fill="#B4DBFF"
                />
                <path
                    d="M17.8571 26.2253H11.982C11.7875 26.2253 11.6299 26.0676 11.6299 25.8731C11.6299 25.6786 11.7875 25.521 11.982 25.521H17.8572C18.0516 25.521 18.2093 25.6786 18.2093 25.8731C18.2093 26.0676 18.0516 26.2253 17.8571 26.2253Z"
                    fill="#B4DBFF"
                />
            </g>
            <defs>
                <clipPath id="clip0_732_58956">
                    <rect width="23.3333" height="23.3333" fill="white" transform="translate(8.3335 8.33325)" />
                </clipPath>
            </defs>
        </svg>
    );
    const CustomIcon = (props) => <Icon component={TestSvg} {...props} />;

    return <CustomIcon />;
}

export default CustomExerciseIcon;
