import Icon from "@ant-design/icons";
import React from "react";

function CustomFilterIcon(props) {
    const SvgPath = () => (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 10.6948H15M2.5 5.69482H17.5M7.5 15.6948H12.5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomFilterIcon;
