import React from "react";
import { useTranslation } from 'react-i18next';
import NoData from "../../../components/NoData";

function MonthlyReport() {
    const { t } = useTranslation();

    return (
        <div>
            <NoData text={t('no_information_available')} />
        </div>
    );
}

export default MonthlyReport;
