import Icon from "@ant-design/icons";
import React from "react";

function CustomUploadIcon(props) {
    const SvgPath = () => (
        <svg width="21" height="22" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 2.5H3M15.5 10.8333L10.5 5.83333M10.5 5.83333L5.5 10.8333M10.5 5.83333V17.5"
                stroke="#1A1918"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomUploadIcon;
