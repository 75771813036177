import Icon from "@ant-design/icons";
import React from "react";

function CustomPauseIcon(props) {
    const SvgPath = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_544_43276" fill="white">
                <rect x="2.87891" y="2" width="8.33674" height="20" rx="1.5" />
            </mask>
            <rect x="2.87891" y="2" width="8.33674" height="20" rx="1.5" stroke="#FFFFFF" strokeWidth="4" mask="url(#path-1-inside-1_544_43276)" />
            <mask id="path-2-inside-2_544_43276" fill="white">
                <rect x="12.7832" y="2" width="8.33674" height="20" rx="1.5" />
            </mask>
            <rect x="12.7832" y="2" width="8.33674" height="20" rx="1.5" stroke="#FFFFFF" strokeWidth="4" mask="url(#path-2-inside-2_544_43276)" />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomPauseIcon;
