import Icon from "@ant-design/icons";
import React from "react";

function CustomBellIcon(props) {
    const SvgPath = () => (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.85493 21C10.5601 21.6224 11.4863 22 12.5008 22C13.5152 22 14.4414 21.6224 15.1466 21M18.5008 8C18.5008 6.4087 17.8686 4.88258 16.7434 3.75736C15.6182 2.63214 14.0921 2 12.5008 2C10.9095 2 9.38333 2.63214 8.25811 3.75736C7.13289 4.88258 6.50075 6.4087 6.50075 8C6.50075 11.0902 5.72122 13.206 4.85042 14.6054C4.11588 15.7859 3.74861 16.3761 3.76208 16.5408C3.77699 16.7231 3.81561 16.7926 3.96253 16.9016C4.09521 17 4.69334 17 5.88961 17H19.1119C20.3082 17 20.9063 17 21.039 16.9016C21.1859 16.7926 21.2245 16.7231 21.2394 16.5408C21.2529 16.3761 20.8856 15.7859 20.1511 14.6054C19.2803 13.206 18.5008 11.0902 18.5008 8Z"
                stroke="#1A1918"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomBellIcon;
