import Icon from "@ant-design/icons";
import React from "react";

function CustomCopyIcon(props) {
    const SvgPath = () => (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0625 5.44727H3.125C2.87636 5.44727 2.6379 5.54604 2.46209 5.72185C2.28627 5.89767 2.1875 6.13613 2.1875 6.38477V17.3223C2.1875 17.5709 2.28627 17.8094 2.46209 17.9852C2.6379 18.161 2.87636 18.2598 3.125 18.2598H14.0625C14.3111 18.2598 14.5496 18.161 14.7254 17.9852C14.9012 17.8094 15 17.5709 15 17.3223V6.38477C15 6.13613 14.9012 5.89767 14.7254 5.72185C14.5496 5.54604 14.3111 5.44727 14.0625 5.44727ZM13.125 16.3848H4.0625V7.32227H13.125V16.3848ZM17.8125 3.57227V14.5098C17.8125 14.7584 17.7137 14.9969 17.5379 15.1727C17.3621 15.3485 17.1236 15.4473 16.875 15.4473C16.6264 15.4473 16.3879 15.3485 16.2121 15.1727C16.0363 14.9969 15.9375 14.7584 15.9375 14.5098V4.50977H5.9375C5.68886 4.50977 5.4504 4.41099 5.27459 4.23518C5.09877 4.05936 5 3.82091 5 3.57227C5 3.32363 5.09877 3.08517 5.27459 2.90935C5.4504 2.73354 5.68886 2.63477 5.9375 2.63477H16.875C17.1236 2.63477 17.3621 2.73354 17.5379 2.90935C17.7137 3.08517 17.8125 3.32363 17.8125 3.57227Z"
                fill="#94A3B8"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomCopyIcon;
