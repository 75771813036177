import Icon from "@ant-design/icons";
import React from "react";

function CustomReportScoreIcon(props) {
    const SvgPath = () => (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1057_71652)">
                <g clipPath="url(#clip1_1057_71652)">
                    <g clipPath="url(#clip2_1057_71652)">
                        <path
                            d="M15.9646 0.942383H14.7513C14.0463 0.942383 13.4727 1.51595 13.4727 2.22095V3.3427C13.4727 3.60157 13.6825 3.81145 13.9414 3.81145C14.2003 3.81145 14.4102 3.60157 14.4102 3.3427V2.22095C14.4102 2.03288 14.5632 1.87988 14.7513 1.87988H15.9646C16.1527 1.87988 16.3057 2.03288 16.3057 2.22095V3.33676C16.3057 3.59563 16.5155 3.80551 16.7744 3.80551C17.0333 3.80551 17.2432 3.59563 17.2432 3.33676V2.22095C17.2432 1.51595 16.6696 0.942383 15.9646 0.942383Z"
                            fill="#607D8B"
                        />
                        <path
                            d="M26.534 32.9424H7.97876C7.44519 32.9424 7.0127 32.5098 7.0127 31.9763V7.38135C7.0127 6.84778 7.44526 6.41528 7.97876 6.41528H26.5339C27.0675 6.41528 27.5 6.84785 27.5 7.38135V31.9763C27.5001 32.5098 27.0675 32.9424 26.534 32.9424Z"
                            fill="#E8D0A2"
                        />
                        <path
                            d="M24.0213 30.5279H5.46606C4.9325 30.5279 4.5 30.0953 4.5 29.5618V4.96679C4.5 4.43323 4.93256 4.00073 5.46606 4.00073H24.0212C24.5548 4.00073 24.9873 4.43329 24.9873 4.96679V29.5618C24.9874 30.0954 24.5549 30.5279 24.0213 30.5279Z"
                            fill="#FFEFCE"
                        />
                        <path
                            d="M23.0547 4.00079V30.528H24.0211C24.5548 30.528 24.9874 30.0956 24.9874 29.5622V4.96655C24.9874 4.43317 24.5548 4.00073 24.0211 4.00073L23.0547 4.00079Z"
                            fill="#EDDBB9"
                        />
                        <path
                            d="M11.624 9.06274C9.56484 9.06274 7.88965 10.747 7.88965 12.8171V17.6261C7.88965 18.1595 8.32209 18.5919 8.85546 18.5919C9.38884 18.5919 9.82127 18.1595 9.82127 17.6261V14.7931H13.4269V17.6261C13.4269 18.1595 13.8593 18.5919 14.3927 18.5919C14.9261 18.5919 15.3585 18.1595 15.3585 17.6261V12.8171C15.3585 10.7469 13.6832 9.06274 11.624 9.06274ZM9.82121 12.8615V12.8171C9.82121 11.8121 10.63 10.9943 11.624 10.9943C12.6181 10.9943 13.4268 11.812 13.4268 12.8171V12.8615H9.82121Z"
                            fill="#00D01F"
                        />
                        <path
                            d="M21.0634 13.4014H20.1157V12.4536C20.1157 12.1947 19.9058 11.9849 19.6469 11.9849C19.388 11.9849 19.1782 12.1947 19.1782 12.4536V13.4014H18.2305C17.9715 13.4014 17.7617 13.6112 17.7617 13.8701C17.7617 14.129 17.9715 14.3389 18.2305 14.3389H19.1782V15.2866C19.1782 15.5455 19.388 15.7554 19.6469 15.7554C19.9058 15.7554 20.1157 15.5455 20.1157 15.2866V14.3389H21.0634C21.3223 14.3389 21.5322 14.129 21.5322 13.8701C21.5322 13.6112 21.3223 13.4014 21.0634 13.4014Z"
                            fill="#00D01F"
                        />
                        <path
                            d="M20.9904 27.1155H8.49944C8.21494 27.1155 7.98438 26.8849 7.98438 26.6004V22.4797C7.98438 22.1952 8.215 21.9646 8.49944 21.9646H20.9904C21.2749 21.9646 21.5054 22.1952 21.5054 22.4797V26.6004C21.5054 26.8849 21.2749 27.1155 20.9904 27.1155Z"
                            fill="#FF7043"
                        />
                        <path
                            d="M18.1382 6.22209H12.5778C12.3353 6.22209 12.1387 6.02546 12.1387 5.78296V3.82909C12.1387 3.30159 12.5663 2.87402 13.0937 2.87402H17.6222C18.1497 2.87402 18.5773 3.30165 18.5773 3.82909V5.78296C18.5773 6.02546 18.3807 6.22209 18.1382 6.22209Z"
                            fill="#FFD740"
                        />
                        <path
                            d="M17.622 2.87402H15.6904C16.2179 2.87402 16.6455 3.30165 16.6455 3.82909V5.78296C16.6455 6.02546 16.4489 6.22209 16.2064 6.22209H18.1379C18.3804 6.22209 18.5771 6.02546 18.5771 5.78296V3.82915C18.5771 3.30165 18.1494 2.87402 17.622 2.87402Z"
                            fill="#FBC02D"
                        />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1057_71652">
                    <rect width="32" height="32" fill="white" transform="translate(0 0.942383)" />
                </clipPath>
                <clipPath id="clip1_1057_71652">
                    <rect width="32" height="32" fill="white" transform="translate(0 0.942383)" />
                </clipPath>
                <clipPath id="clip2_1057_71652">
                    <rect width="32" height="32" fill="white" transform="translate(0 0.942383)" />
                </clipPath>
            </defs>
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomReportScoreIcon;
