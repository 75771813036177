import { Form, Input, message, Modal, notification, Typography } from "antd";
import React, { useContext, useState } from "react";
import { debounce, logout } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { StoreContext } from "../../stores";
import { useTranslation } from "react-i18next";
import CustomLockIcon from "../Icons/CustomLockIcon";
import CustomSuccessIcon from "../Icons/CustomSuccessIcon";
import { AAUTH } from "../../apis/auth";

function ModalUpdatePassword(props) {
    const { open, close } = props;
    const { setMe, me } = useContext(StoreContext);
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [error, setError] = useState(false);

    const updatePassword = useMutation({
        mutationFn: (values) => {
            message.loading(t("updating_information"));
            return handleFetchApi(AAUTH.updatePassword({ data: values }));
        },
        onSuccess: (response) => {
            message.destroy();
            if (response?.status !== "S0000") {
                setError(response.status);
                return;
            }
            setMe({ ...me, ...response?.data });
            Modal.success({
                title: t("password_update_success"),
                content: t("password_update_success_content"),
                icon: <CustomSuccessIcon />,
                centered: true,
            });
            handleClose();
            setTimeout(() => {
                logout();
            }, 1500);
            return;
        },
    });

    function isValidPassword(password) {
        const minLength = 8;
        const uppercasePattern = /[A-Z]/;

        if (password.length < minLength) {
            return false;
        }

        if (!uppercasePattern.test(password)) {
            return false;
        }

        return true;
    }

    const handleClose = () => {
        setError(false);
        form.resetFields();
        close();
    };

    return (
        <Modal
            zIndex={1033}
            open={open}
            width={500}
            title={t("change_password")}
            okText={t("change_password")}
            cancelText={t("cancel")}
            onCancel={handleClose}
            okButtonProps={{ form: "updatePassword", key: "submit", htmlType: "submit" }}
        >
            <Form
                id="updatePassword"
                requiredMark={false}
                layout="vertical"
                name="update_password"
                onFinish={updatePassword.mutate}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    help={error && t(error)}
                    validateStatus={error ? "error" : ""}
                    label={t("current_password")}
                    name="old_password"
                    rules={[{ required: true, message: t("pls_password") }]}
                >
                    <Input.Password
                        onChange={debounce((e) => setError(false))}
                        type="password"
                        size="large"
                        prefix={<CustomLockIcon />}
                        placeholder={t("old_password")}
                    />
                </Form.Item>
                <Form.Item
                    label={t("new_password")}
                    name="new_password"
                    rules={[
                        { required: true, message: t("pls_password") },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || isValidPassword(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t("password_requirements")));
                            },
                        }),
                    ]}
                >
                    <Input.Password maxLength={32} size="large" prefix={<CustomLockIcon />} placeholder={t("plh_new_password")} />
                </Form.Item>
                <Form.Item
                    label={t("confirm_new_password")}
                    name="confirm_password"
                    rules={[
                        {
                            required: true,
                            message: t("please_confirm_password"),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("new_password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t("password_mismatch")));
                            },
                        }),
                    ]}
                >
                    <Input.Password maxLength={32} size="large" prefix={<CustomLockIcon />} placeholder={t("plh_confirm_new_password")} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ModalUpdatePassword;
