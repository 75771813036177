import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import Report from "../../../components/Report";
import { StoreContext } from "../../../stores";
import ReportHistory from "../../../components/ReportHistory";
import bad from "../../../assets/images/bad.png";
import { AREPORT } from "../../../apis/report";

function DailyReport() {
    const { me } = useContext(StoreContext);
    const { t } = useTranslation();
    const today = moment().format("YYYY-MM-DD");

    const { data: reportData, isLoading } = useQuery(
        ["student_report", today],
        async () => {
            const response = await handleFetchApi(AREPORT.search({ params: `?class_id=${me?.schoolClasses[0]?.id}&report_date=${today}` }));
            return response?.data[0];
        },
        {
            staleTime: 300000, // 5 minutes
            cacheTime: 300000,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <div className="loading_container">
            <br />
            <Typography.Title level={4} className="flex__between__center">
                {t("date")} {moment().format("DD/MM/YYYY")}
            </Typography.Title>
            {isLoading ? <Loading /> : reportData ? <Report reportData={reportData} /> : <NoData img={bad} imageWidth={100} text={t("no_comments_today")} />}
            <ReportHistory params={`?class_id=${me?.schoolClasses[0]?.id}`} />
        </div>
    );
}

export default DailyReport;
