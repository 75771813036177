import React, { useContext } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { StoreContext } from "../../../stores";

function Video(props) {
    const { media, setMedia } = useContext(StoreContext);
    return (
        media?.type === "Video" && (
            <div className="cb_video flex__center__center">
                <Button className="cb_video__close" onClick={() => setMedia()} icon={<CloseOutlined />} type="primary" danger size="large" />
                <video width="100%" height="100%" controls>
                    <source src={media?.children[0]?.content?.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    );
}

export default Video;
