/* eslint-disable import/no-anonymous-default-export */
import { message, notification } from "antd";
import React, { useEffect, useState } from "react";

export const BookContext = React.createContext(null);

const COVER = [
    {
        key: "cover",
        index: 0,
    },
];

export default ({ children }) => {
    const [bookName, setBookName] = useState("");
    const [bookCover, setBookCover] = useState("");
    const [pages, setPages] = useState([]);
    const [scale, setScale] = useState(1);
    const [tabletMode, setTabletMode] = useState(window.innerWidth < 1024);
    const [pageView, setPageView] = useState(COVER);

    useEffect(() => {
        const handleResize = () => {
            const newMode = window.innerWidth < 1024
            if (tabletMode !== newMode) {
                console.log('NEED RELOAD');
                message.info("Đang chuyển chế độ màn hình");
                window.location.reload();
                setTabletMode(newMode);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [tabletMode]);

    const bookStores = {
        pageView: pageView,
        setPageView: setPageView,
        bookName: bookName,
        setBookName: setBookName,
        bookCover: bookCover,
        setBookCover: setBookCover,
        pages: pages,
        setPages: setPages,
        scale: scale,
        setScale: setScale,
        tabletMode: tabletMode,
        setTabletMode: setTabletMode,
    };

    return <BookContext.Provider value={bookStores}>{children}</BookContext.Provider>;
};
