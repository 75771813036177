import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartScore = ({ assignmentGradings }) => {
    // Custom function to group by scaled_rating
    const groupByScaledRating = (data) => {
        return data.reduce((acc, item) => {
            const rating = item.evaluation?.scaled_rating;
            if (rating) {
                acc[rating] = (acc[rating] || 0) + 1; // Count by scaled_rating value
            }
            return acc;
        }, {});
    };

    // Group the assignmentGradings by scaled_rating
    const groupedData = groupByScaledRating(assignmentGradings);
    // Prepare data for the chart
    const labels = {
        BAD: "<5",
        AVERAGE: "5 - 8",
        GOOD: ">8",
    };
    const dataValues = Object.keys(labels).map((key) => groupedData[key] || 0); // Use keys from labels for dataValues
    const data = {
        labels: Object.values(labels),
        datasets: [
            {
                label: null,
                data: dataValues,
                backgroundColor: "#FFBF00",
                borderWidth: 0,
                borderRadius: 4,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="chart-container">
            <Bar data={data} options={options} />
        </div>
    );
};

export default ChartScore;
