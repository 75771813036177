import Icon from "@ant-design/icons";
import React from "react";

function CustomSuccessIcon(props) {
    const SvgPath = () => (
        <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.447266" width="72" height="72" rx="36" fill="#DCFCE7" />
            <path d="M52 24.4473L30 46.4473L20 36.4473" stroke="#22C55E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomSuccessIcon;
