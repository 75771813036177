import React from "react";
import { ReactComponent as LoadingSVG } from "./loading.svg";
import { Typography } from "antd";
import { t } from "i18next";

function Loading(props) {

    return (
        <div className={`app_loading flex__center__center flex__column gap`}>
            <LoadingSVG />
            <Typography.Text>{t("loading")}</Typography.Text>
        </div>
    );
}

export default Loading;
