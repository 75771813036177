import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { handleFetchApi } from "../../../configs/apiRequest";
import { Button, message, Typography } from "antd";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useContext } from "react";
import { StoreContext } from "../../../stores";
import ErrorPage from "../../Error";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import { AAUTH } from "../../../apis/auth";

function SessionValidator(props) {
    const { session_id } = useParams();
    const { setMe } = useContext(StoreContext);
    const {
        data: sessionData,
        isLoading,
        isError,
    } = useQuery(
        [session_id],
        async () => {
            const sessionResp = await handleFetchApi(AAUTH.getAccessToken({ session_id }));
            let me = sessionResp?.data?.accessTokenDto;
            if (!me) {
                message.error("Phiên đăng nhập này không có quyền truy cập hoặc đã hết hạn, vui lòng thử lại!");
                return;
            }
            message.success(t("login_success_msg"));
            Cookies.set("access_token", me.access_token, { expires: 7 });
            Cookies.set("refresh_token", me.refresh_token);
            setMe(me);
            setTimeout(() => {
                window.location = "/";
            }, 1500);
            return me;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
    if (isLoading) return <Loading />;
    if (isError)
        return (
            <div className="flex__center__center flex__column">
                <NoData text="Phiên đăng nhập này không có quyền truy cập hoặc đã hết hạn, vui lòng thử lại!" />
                <br />
                <Button type="primary" size="large">
                    <Link to="https://sso.cloudbook.vn">Về trang đăng nhập</Link>
                </Button>
            </div>
        );
    return (
        <div className="flex__center__center">
            <Typography.Title level={5} type="success">
                Đang đăng nhập, bạn sẽ được chuyển hướng sau vài giây!
            </Typography.Title>
        </div>
    );
}

export default SessionValidator;
