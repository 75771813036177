import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { handleFetchApi } from "../../configs/apiRequest";
import { Button, Col, Input, Layout, notification, Progress, Row, Table, Tag, Typography } from "antd";
import moment from "moment";
import Loading from "../../components/Loading";
import CustomSearchIcon from "../../components/Icons/CustomSearchIcon";
import ChartScore from "./components/ChartScore";
import { debounce } from "../../utils";
import CustomFileIcon from "../../components/Icons/CustomFileIcon";
import CustomBarChartIcon from "../../components/Icons/CustomBarChartIcon";
import CustomUserCheckIcon from "../../components/Icons/CustomUserCheckIcon";
import NoData from "../../components/NoData";
import CustomDownloadIcon from "../../components/Icons/CustomDownloadIcon";
import CustomEditIcon from "../../components/Icons/CustomEditIcon";
import CustomTrashIcon from "../../components/Icons/CustomTrashIcon";
import { fixedNumber } from "../../utils/validation";
import CustomPenIcon from "../../components/Icons/CustomPenIcon";
import ModalUpdateAssignment from "./components/ModalUpdateAssignment";
import { useTranslation } from "react-i18next";
import { exportAssignmentMarkByClass } from "../../utils/export";
import { AASSIGNMENT } from "../../apis/exercise";

const { Content } = Layout;

function ManageAssignment(props) {
    const { t } = useTranslation();
    const { assignment_id } = useParams();
    const navigate = useNavigate();
    const [modal, setModal] = useState({
        open: false,
        data: null,
    });
    const [filtered, setFiltered] = useState();
    const {
        refetch,
        data: assignmentDetails,
        isLoading,
    } = useQuery(
        [assignment_id],
        async () => {
            const res = await handleFetchApi(AASSIGNMENT.getDetail({ assignment_id }));
            return res?.data;
        },
        {
            staleTime: 120000, // 2 minutes
            cacheTime: 120000,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const columns = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            render: (value, record, index) => index + 1,
        },
        {
            title: t("fullName"),
            dataIndex: "name",
            key: "name",
            render: (value, record) => (
                <div className="">
                    <Typography.Text strong>{record?.student?.name}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">{record?.student?.username}</Typography.Text>
                </div>
            ),
        },
        {
            key: "evaluation",
            title: t("totalScore"),
            dataIndex: "evaluation",
            render: (value, record) => fixedNumber(value?.mark),
            sorter: (a, b) => a.evaluation?.mark || 0 - b.evaluation?.mark || 0,
        },
        {
            title: t("start"),
            dataIndex: "startedAt",
            key: "startedAt",
            render: (value, record) =>
                !record?.startedAt ? <Tag color="#FFF1F2">{t("notStarted")}</Tag> : moment(record?.startedAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: t("submit"),
            dataIndex: "submittedAt",
            key: "submittedAt",
            render: (value, record) =>
                !record?.submittedAt ? <Tag color="#FFF1F2">{t("notSubmitted")}</Tag> : moment(record?.submittedAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            render: (value, record) => (
                <Button
                    onClick={() => {
                        if (!record?.submittedAt) {
                            return notification.error({ message: t("studentNotCompletedAssignment") });
                        }
                        return navigate(`/assignment/${assignmentDetails?.assignment?.id}/grading/${record?.id}`);
                    }}
                    type="link"
                >
                    {t("viewComment")}
                </Button>
            ),
        },
    ];

    const handleFilterStudents = (keyword) => {
        if (!keyword) {
            setFiltered();
            return;
        }
        const results = assignmentDetails?.assignmentGradings?.filter((b) => {
            const tLowercase = b.student?.name?.toLowerCase();
            return tLowercase.includes(keyword);
        });
        if (results?.length === assignmentDetails?.assignmentGradings?.length) setFiltered([]);
        else setFiltered(results || []);
    };

    return (
        <Content>
            {isLoading ? (
                <Loading />
            ) : (
                <div id="manage_assignment" className="page manage_assignment">
                    <Row gutter={[12, 24]} align={"stretch"}>
                        <Col span={24}>
                            <Typography.Title level={5} className="flex__between__center">
                                {assignmentDetails?.assignment?.name}
                            </Typography.Title>
                        </Col>
                        <Col span={10}>
                            <div className="card">
                                <div className="card__header">
                                    <CustomFileIcon /> {t("information")}
                                    <Button type="link" icon={<CustomPenIcon />} onClick={() => setModal({ open: true, data: assignmentDetails?.assignment })}>
                                        {t("edit")}
                                    </Button>
                                </div>
                                <div className="card__body">
                                    <Typography.Text>
                                        {t("name")}: <b>{assignmentDetails?.assignment?.name}</b>
                                    </Typography.Text>{" "}
                                    <br />
                                    <Typography.Text>
                                        {t("class")}: <b>Develop</b>
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        {t("createdDate")}: <b>{moment(assignmentDetails?.assignment?.createAt).format("DD/MM/YYYY HH:mm")}</b>
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        {t("dueDate")}:{" "}
                                        <b>
                                            {assignmentDetails?.assignment?.deadlineAt
                                                ? moment(assignmentDetails?.assignment?.deadlineAt).format("DD/MM/YYYY HH:mm")
                                                : t("none")}
                                        </b>
                                    </Typography.Text>
                                </div>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className="card">
                                <div className="card__header">
                                    <span>
                                        <CustomBarChartIcon /> {t("scoreStatistics")}
                                    </span>
                                </div>
                                <div className="card__body">
                                    <ChartScore assignmentGradings={assignmentDetails?.assignmentGradings} />
                                </div>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className="card">
                                <div className="card__header">
                                    <CustomUserCheckIcon /> {t("studentsCompleted")}
                                </div>
                                <div className="card__body flex__end__center flex__column gap">
                                    <Typography.Text className="student_completed">
                                        <span className="large">{assignmentDetails?.perOfStudentCompleted?.split("/")[0]}</span>
                                        <span className="small">/{assignmentDetails?.perOfStudentCompleted?.split("/")[1]}</span>
                                    </Typography.Text>
                                    <Progress
                                        percent={
                                            fixedNumber(
                                                assignmentDetails?.perOfStudentCompleted?.split("/")[0] /
                                                    assignmentDetails?.perOfStudentCompleted?.split("/")[1]
                                            ) * 100
                                        }
                                        strokeWidth={15}
                                        strokeColor="#22C55E"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Table
                                title={() => (
                                    <div className="flex__between__center">
                                        <Input
                                            onChange={debounce((e) => handleFilterStudents(e.target.value?.toLowerCase()), 300)}
                                            prefix={<CustomSearchIcon />}
                                            placeholder={t("searchStudent")}
                                            size="large"
                                        />
                                        <Button
                                            onClick={() =>
                                                exportAssignmentMarkByClass(assignmentDetails?.assignmentGradings, assignmentDetails?.assignment?.name)
                                            }
                                            icon={<CustomDownloadIcon />}
                                            className="flex__start__center btn-white --md"
                                        >
                                            {t("download")}
                                        </Button>
                                    </div>
                                )}
                                locale={{ emptyText: <NoData imageWidth={70} text={t("no_data")} /> }}
                                pagination={{ pageSize: 8 }}
                                dataSource={filtered ?? assignmentDetails?.assignmentGradings}
                                columns={columns}
                                rowKey={(record) => record?.student?.id}
                            />
                        </Col>
                    </Row>
                </div>
            )}
            <ModalUpdateAssignment open={modal?.open} assignment={modal?.data} close={() => setModal({ open: false })} refetch={refetch} />
        </Content>
    );
}

export default ManageAssignment;
