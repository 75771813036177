import React, { useState, useCallback, useEffect } from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import BookItem from "./BookItem";
import NoData from "../../../components/NoData";

const BookList = ({ items, currentBook, setSelectedBook: updateBook }) => {
    const { t } = useTranslation();
    const [selectedBook, setSelectedBook] = useState(null);

    const handleSelectBook = (book) => updateBook(book);

    useEffect(() => {
        setSelectedBook(currentBook);
    }, [currentBook]);

    if (!items || items.length === 0) {
        return <NoData text={t("noSearchResults")} />;
    }

    return (
        <Row gutter={16}>
            {items.map((book, index) => (
                <BookItem key={`${book.book_id}-${index}`} book={book} selectedBook={currentBook || selectedBook} onSelect={handleSelectBook} />
            ))}
        </Row>
    );
};

export default BookList;
