import Icon from "@ant-design/icons";
import React from "react";

function CustomBookIconLarge(props) {
    const TestSvg = () => (
        <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_904_28241)">
                <path
                    d="M52.5181 52.434H9.17622C6.35205 52.434 4.0625 50.1403 4.0625 47.3107C4.0625 44.4813 6.35194 42.1875 9.17622 42.1875H52.5181C50.2595 45.2282 50.2595 49.3932 52.5181 52.434Z"
                    fill="#FFF7E8"
                />
                <path
                    d="M53.9579 56.6285H9.30037C4.17211 56.6285 0 52.4486 0 47.3108C0 42.173 4.17211 37.9932 9.30037 37.9932H53.9579C55.0857 37.9932 56 38.9092 56 40.0391C56 41.169 55.0857 42.0851 53.9579 42.0851H9.30037C6.42414 42.0851 4.08428 44.4294 4.08428 47.3109C4.08428 50.1924 6.42425 52.5368 9.30037 52.5368H53.9579C55.0857 52.5368 56 53.4528 56 54.5827C56 55.7125 55.0857 56.6285 53.9579 56.6285Z"
                    fill="#FF4B6F"
                />
                <path
                    d="M52.5181 15.0697H9.17622C6.35205 15.0697 4.0625 12.776 4.0625 9.94648C4.0625 7.11706 6.35194 4.82324 9.17622 4.82324H52.5181C50.2595 7.86398 50.2595 12.029 52.5181 15.0697Z"
                    fill="#FFF7E8"
                />
                <path
                    d="M53.9579 19.2642H9.30037C4.17211 19.2642 0 15.0843 0 9.94656C0 4.80878 4.17211 0.628906 9.30037 0.628906H53.9579C55.0857 0.628906 56 1.54492 56 2.67488C56 3.80483 55.0857 4.72084 53.9579 4.72084H9.30037C6.42414 4.72084 4.08428 7.06519 4.08428 9.94667C4.08428 12.8282 6.42425 15.1725 9.30037 15.1725H53.9579C55.0857 15.1725 56 16.0885 56 17.2185C56 18.3482 55.0857 19.2642 53.9579 19.2642Z"
                    fill="#FF802C"
                />
                <path
                    d="M3.48242 23.5527H46.8244C49.6485 23.5527 51.9381 25.8464 51.9381 28.676C51.9381 31.5055 49.6486 33.7992 46.8244 33.7992H3.48242C5.74102 30.7585 5.74102 26.5935 3.48242 23.5527Z"
                    fill="#FFF7E8"
                />
                <path
                    d="M2.04214 19.3584H46.6996C51.8279 19.3584 56 23.5383 56 28.6761C56 33.8138 51.8279 37.9937 46.6996 37.9937H2.04214C0.914266 37.9937 0 37.0777 0 35.9477C0 34.8178 0.914266 33.9018 2.04214 33.9018H46.6996C49.5759 33.9018 51.9157 31.5574 51.9157 28.6759C51.9157 25.7945 49.5757 23.4501 46.6996 23.4501H2.04214C0.914266 23.4502 0 22.5342 0 21.4043C0 20.2744 0.914266 19.3584 2.04214 19.3584Z"
                    fill="#227DBF"
                />
                <path
                    d="M46.7004 19.3584H42.1934C46.3027 19.3584 49.7958 22.0434 51.0225 25.7536C51.5867 26.5883 51.9166 27.5942 51.9166 28.6761C51.9166 29.7578 51.5867 30.7638 51.0225 31.5986C49.7958 35.3087 46.3027 37.9937 42.1934 37.9937H46.7004C51.8286 37.9937 56.0008 33.8138 56.0008 28.6761C56.0008 23.5382 51.8286 19.3584 46.7004 19.3584Z"
                    fill="#005592"
                />
                <path d="M34.6562 28.6758H42.0788V46.1916L38.4326 42.6692L34.6562 46.1916V28.6758Z" fill="#FFC456" />
            </g>
            <defs>
                <clipPath id="clip0_904_28241">
                    <rect width="56" height="56" fill="white" transform="translate(0 0.628906)" />
                </clipPath>
            </defs>
        </svg>
    );
    const CustomIcon = (props) => <Icon component={TestSvg} {...props} />;

    return <CustomIcon />;
}

export default CustomBookIconLarge;
