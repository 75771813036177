import Icon from "@ant-design/icons";
import React from "react";

function CustomWalletIcon(props) {
    const SvgPath = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.0008 10L5.00082 10M19.0008 10L20.865 13.1069C21.2828 13.8032 21.4916 14.1514 21.6001 14.5262C21.6963 14.8582 21.7343 15.2045 21.7125 15.5495C21.6879 15.9389 21.5595 16.324 21.3027 17.0944L21.0949 17.7179C20.7014 18.8981 20.5047 19.4882 20.1399 19.9245C19.8177 20.3098 19.4039 20.608 18.9366 20.7918C18.4073 21 17.7852 21 16.5412 21L7.46046 21C6.21639 21 5.59436 21 5.06508 20.7918C4.5977 20.608 4.18394 20.3098 3.86175 19.9245C3.4969 19.4882 3.3002 18.8981 2.90679 17.7179L2.69895 17.0944C2.44217 16.324 2.31378 15.9389 2.28917 15.5495C2.26737 15.2044 2.30538 14.8582 2.40151 14.5261C2.50999 14.1514 2.71888 13.8032 3.13667 13.1069L5.00082 10M19.0008 10L19.4181 8.53955C19.6159 7.84717 19.7148 7.50097 19.6371 7.22708C19.5691 6.98715 19.4138 6.78132 19.2018 6.64996C18.9598 6.5 18.5997 6.5 17.8797 6.5L6.12199 6.5C5.40189 6.5 5.04185 6.5 4.79984 6.64996C4.58784 6.78132 4.43258 6.98715 4.36451 7.22708C4.28681 7.50097 4.38573 7.84717 4.58355 8.53955L5.00082 10M12.0008 6.5H8.46511C7.94415 6.5 7.44452 6.28929 7.07615 5.91421C6.70777 5.53914 6.50082 5.03043 6.50082 4.5C6.50082 3.96957 6.70777 3.46086 7.07615 3.08579C7.44452 2.71071 7.94415 2.5 8.46511 2.5C11.2151 2.5 12.0008 6.5 12.0008 6.5ZM12.0008 6.5H15.5365C16.0575 6.5 16.5571 6.28929 16.9255 5.91421C17.2939 5.53914 17.5008 5.03043 17.5008 4.5C17.5008 3.96957 17.2939 3.46086 16.9255 3.08579C16.5571 2.71071 16.0575 2.5 15.5365 2.5C12.7865 2.5 12.0008 6.5 12.0008 6.5Z"
                stroke="#F3A000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomWalletIcon;
