import Icon from "@ant-design/icons";
import React from "react";

function CustomPenIcon(props) {
    const SvgPath = () => (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.89735 15.0963C2.93564 14.7517 2.95478 14.5794 3.00691 14.4184C3.05316 14.2755 3.11851 14.1396 3.20118 14.0142C3.29436 13.8729 3.41694 13.7503 3.66209 13.5052L14.6673 2.49992C15.5878 1.57945 17.0802 1.57945 18.0007 2.49993C18.9211 3.4204 18.9211 4.91279 18.0007 5.83326L6.99542 16.8385C6.75027 17.0836 6.6277 17.2062 6.48639 17.2994C6.36102 17.3821 6.22506 17.4474 6.08219 17.4937C5.92115 17.5458 5.74887 17.5649 5.40429 17.6032L2.58398 17.9166L2.89735 15.0963Z"
                stroke="#1A1918"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomPenIcon;
