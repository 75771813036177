import { Button, Col, Descriptions, Divider, Image, Layout, Row, Tag, Typography } from "antd";
import React from "react";
import { imageFallback } from "../../utils";
import { FallOutlined } from "@ant-design/icons";

const { Content } = Layout;

function SuggestionBook(props) {
    return (
        <Content>
            <div className="page suggest_book">
                <Row gutter={[24, 24]} >
                    <Col span={8}>
                        <div className="card --padding --lg">
                            <Image
                                width="100%"
                                src={"https://hieusach24h.com/wp-content/uploads/2021/10/phieu-bai-tap-cuoi-tuan-mon-toan-1-ket-noi-tri-thuc.jpg"}
                                alt="book_image"
                                fallback={imageFallback}
                            />
                            <Typography.Title style={{ marginTop: 12 }} level={4}>
                                Bài tập Toán 1 - Tập 1
                            </Typography.Title>
                            <div className="flex__between__center">
                                <Button style={{ width: "45%" }} type="primary" danger size="large">
                                    MUA NGAY
                                </Button>
                                <Button style={{ width: "45%" }} type="primary" size="large">
                                    ĐỌC THỬ
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className="">
                            <Typography.Title level={3}>Bài tập Toán 1 - Tập 1 (Kết Nối Tri Thức) (Chuẩn)</Typography.Title>
                            <Typography.Text type="secondary">
                                Nhà xuất bản: <b>Giáo Dục Việt Nam</b>
                            </Typography.Text>
                            <br />
                            <Typography.Text type="secondary">
                                Tác giả: <b>Nhiều tác giả</b>
                            </Typography.Text>
                            <div style={{ marginTop: 12 }}>
                                <Typography.Text type="secondary" delete>
                                    16.000 đồng
                                </Typography.Text>
                                <Typography.Title style={{ margin: 0 }} level={3} type="danger">
                                    14.400 đồng{" "}
                                    <small style={{ color: "#22C55E", fontSize: 16 }}>
                                        <FallOutlined twoToneColor="#F43F5E" /> 10%
                                    </small>
                                </Typography.Title>
                            </div>
                        </div>
                        <Divider />
                        <div className="">
                            <Typography.Title level={4}>Thông tin chi tiết</Typography.Title>
                            <Descriptions bordered column={1} size="small" labelStyle={{ fontWeight: 700 }}>
                                <Descriptions.Item label="Mã hàng">9786040338839</Descriptions.Item>
                                <Descriptions.Item label="Cấp Độ/ Lớp">Lớp 1</Descriptions.Item>
                                <Descriptions.Item label="Cấp Học">Cấp 1</Descriptions.Item>
                                <Descriptions.Item label="Nhà Cung Cấp">Nhà xuất bản Giáo Dục</Descriptions.Item>
                                <Descriptions.Item label="Tác giả">Nhiều Tác Giả</Descriptions.Item>
                                <Descriptions.Item label="NXB">Giáo Dục Việt Nam</Descriptions.Item>
                                <Descriptions.Item label="Năm XB">2023</Descriptions.Item>
                                <Descriptions.Item label="Ngôn Ngữ">Tiếng Việt</Descriptions.Item>
                                <Descriptions.Item label="Trọng lượng (gr)">207</Descriptions.Item>
                                <Descriptions.Item label="Kích Thước Bao Bì">26.5 x 19 x 0.5 cm</Descriptions.Item>
                                <Descriptions.Item label="Số trang">116</Descriptions.Item>
                                <Descriptions.Item label="Hình thức">Bìa Mềm</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Col>
                </Row>
            </div>
        </Content>
    );
}

export default SuggestionBook;
