import { Input, message, Modal, notification, Typography } from "antd";
import React, { useContext, useState } from "react";
import { debounce } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import { StoreContext } from "../../stores";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import CustomDangerIcon from "../Icons/CustomDangerIcon";
import CustomSuccessIcon from "../Icons/CustomSuccessIcon";
import { AAUTH } from "../../apis/auth";

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

function ModalUpdateEmail(props) {
    const { open, close } = props;
    const { setLoading, loading } = useContext(StoreContext);
    const { t } = useTranslation(["translation"]);

    const [name, setName] = useState();

    const updateName = useMutation({
        mutationFn: (values) => {
            if (!isValidEmail(name)) {
                message.error("Vui lòng nhập đúng định dạng email!");
                return Promise.reject("Invalid email format");
            }
            message.loading("Đang cập nhật thông tin ...");
            setLoading(true);
            return handleFetchApi(AAUTH.addEmail({ data: { email: name, callbackUrl: `${window.location.origin}/verify_email` } }));
        },
        onSuccess: (response) => {
            message.destroy();
            console.log("response::", response);

            if (response?.status !== "S0000") {
                setLoading(false);
                Modal.error({
                    title: t("error_title"),
                    icon: <CustomDangerIcon />,
                    content: t(response?.status),
                    zIndex: 1055,
                    centered: true,
                });
                return;
            }
            Modal.success({
                title: t("success"),
                icon: <CustomSuccessIcon />,
                content: "Vui lòng kiểm tra email mà bạn đã đăng ký.",
                zIndex: 1055,
                centered: true,
            });
            close();
            setLoading(false);
            return;
        },
        onError: (error) => {
            setLoading(false);
            if (error === "Invalid email format") {
                // Error already displayed
            } else {
                message.error("Có lỗi xảy ra. Vui lòng thử lại sau!");
            }
        },
    });

    return (
        <Modal zIndex={1033} open={open} width={500} title="Cập nhật Email" okText="LƯU" cancelText="HỦY" onCancel={close} onOk={updateName.mutate}>
            <Typography.Text>Email của bạn</Typography.Text>
            <Input style={{ marginBottom: 12 }} size="large" onChange={debounce((e) => setName(e?.target?.value))} />

            <Typography.Text style={{ fontStyle: "italic" }} type="secondary">
                Thêm một email sẽ giúp bạn lấy lại mật khẩu khi cần. <br /> Chúng tôi sẽ gửi cho bạn một email để xác nhận thông tin.
            </Typography.Text>
            {loading && <Loading />}
        </Modal>
    );
}

export default ModalUpdateEmail;
