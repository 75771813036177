import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import packageJson from "../../package.json"; // Import the package.json as a module

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        lng: localStorage.getItem("i18nextLng") || "vn",
        fallbackLng: localStorage.getItem("i18nextLng") || "vn",
        debug: false,
        defaultNS: "translation",
        backend: {
            loadPath: `/locales/{{lng}}/translation.json?v=${packageJson?.version}`,
        },
        interpolation: {
            escapeValue: true,
        },
        react: {
            useSuspense: false,
        },
    });

export { i18n };
