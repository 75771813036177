import { Rate } from 'antd';
import React from 'react';

function RatingByMark({mark = 0}) {

    const renderByMark = () => {
        if(!mark) return 0
        if(mark < 5) return 1
        if(mark >=5 && mark <= 8) return 2
        if(mark > 8) return 3
    }
    
    return (
        <Rate defaultValue={renderByMark()} count={3} disabled />
    );
}

export default RatingByMark;