import React from "react";
import { Image } from "antd";
import { imageFallback } from "../../../utils";

const PreviewAssignment = ({ items }) => (
    <>
        {items?.map((item) => (
            <Image key={item.key} src={item.image} fallback={imageFallback} />
        ))}
    </>
);

export default PreviewAssignment;
