import React, { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { message, notification } from "antd";
import { StoreContext } from "../stores";
import { checkValidSesson } from "../utils";
import Audio from "./Media/Audio";
import Video from "./Media/Video";
import Loading from "../components/Loading";

message.config({
    maxCount: 2,
});
notification.config({
    placement: "bottomRight",
    maxCount: 3,
});

const notRequiredLogin = ["login", "error-page", "notfound", "session", "forgot_password", "set_new_password", "verify_email"];

function Views() {
    const queryClient = new QueryClient();
    const { me, setMe } = useContext(StoreContext);
    const isLoginPage = notRequiredLogin.includes(window?.location?.pathname?.split("/")[1]);
    // check valid session user when f5 browser
    useEffect(() => {
        const getCurrentSession = async () => {
            await checkValidSesson(me, setMe);
        };
        if (!me && !isLoginPage) getCurrentSession();
    }, []);

    if (me || isLoginPage) {
        return (
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}></RouterProvider>
                <Audio />
                <Video />
            </QueryClientProvider>
        );
    } else return <Loading />;
}

export default Views;
