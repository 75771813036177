import React, { useState } from "react";
import { Button, Mentions, Spin, Typography, Modal, Table } from "antd";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { suggestBad, suggestCols, suggestGood } from "../helper";
import { AEDUCATION } from "../../../apis/education";

const MentionsStudent = ({ classId, onChange, initValue, type }) => {
    const [value, setValue] = useState(initValue || "");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { data, isLoading, error } = useQuery(["students", classId], async () => {
        const response = await handleFetchApi(AEDUCATION.getStudentsByClass({ classId }));
        return (response?.data?.content || []).map((student) => ({
            value: student.name,
            label: `${student.name} - ${student?.username}`,
            id: student.id,
        }));
    });

    const handleChange = (newValue) => {
        setValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleRowClick = (content) => {
        const newValue = `${value}${content} `; // Append selected student's name
        setValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
        setIsModalVisible(false); // Close modal after selection
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    if (isLoading) return <Spin />;
    if (error) return <Typography.Text type="danger">Error loading students</Typography.Text>;

    return (
        <div className="report-mentions">
            <Button className="btn__suggest" type="link" size="small" onClick={showModal}>
                Mẫu nhận xét
            </Button>
            <Mentions value={value} onChange={handleChange} placeholder="Type @ to select student" rows={4} options={data}></Mentions>
            <Modal zIndex={1003} centered={true} width={"90vw"} title="Mẫu nhận xét" open={isModalVisible} onCancel={handleCancel} footer={null}>
                <Table
                    className="suggest-table"
                    dataSource={type === "GOOD" ? suggestGood : suggestBad}
                    columns={suggestCols}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: (event) => {
                            const columnKey = event.target.closest("td");
                            if (columnKey) {
                                handleRowClick(columnKey.textContent); // Pass the record and column text to the handler
                            }
                        },
                    })}
                />
            </Modal>
        </div>
    );
};

export default MentionsStudent;
