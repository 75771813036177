export const ANOTIFICATION = {
    getNoti: ({ page }) => ({
        method: "GET",
        url: `/notification?page=${page}&size=10`
    }),
    readAll: () => ({
        method: 'PUT',
        url: `/notification/read/all`
    }),
    readOnly: ({id}) => ({
        method: 'PUT',
        url: `/notification/read/${id}`
    })
};
