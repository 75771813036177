import Icon from "@ant-design/icons";
import React from "react";

function CustomBarChartIcon(props) {
    const SvgPath = () => (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.5 15.832V17.832M12.5 11.832V17.832M16.5 7.83203V17.832M8.3 21.832H16.7C18.3802 21.832 19.2202 21.832 19.862 21.5051C20.4265 21.2174 20.8854 20.7585 21.173 20.194C21.5 19.5523 21.5 18.7122 21.5 17.032V8.63203C21.5 6.95187 21.5 6.1118 21.173 5.47006C20.8854 4.90557 20.4265 4.44663 19.862 4.15901C19.2202 3.83203 18.3802 3.83203 16.7 3.83203H8.3C6.61984 3.83203 5.77976 3.83203 5.13803 4.15901C4.57354 4.44663 4.1146 4.90557 3.82698 5.47006C3.5 6.1118 3.5 6.95187 3.5 8.63203V17.032C3.5 18.7122 3.5 19.5523 3.82698 20.194C4.1146 20.7585 4.57354 21.2174 5.13803 21.5051C5.77976 21.832 6.61984 21.832 8.3 21.832Z"
                stroke="#8C8984"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomBarChartIcon;
