import { Button, Drawer, Dropdown, message, Modal, notification, Space, Table, Tag, Typography } from "antd";
import React, { useContext, useState } from "react";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import { StoreContext } from "../../../stores";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomTestIcon from "../../../components/Icons/CustomTestIcon";
import CustomExerciseIcon from "../../../components/Icons/CustomExerciseIcon";
import Loading from "../../../components/Loading";
import GiveAssignment from "../../GiveAssignment";
import { useNavigate, useParams } from "react-router-dom";
import CustomEditIcon from "../../../components/Icons/CustomEditIcon";
import CustomTrashIcon from "../../../components/Icons/CustomTrashIcon";
import { PlusOutlined } from "@ant-design/icons";
import CustomWarningIcon from "../../../components/Icons/CustomWarningIcon";
import { AASSIGNMENT } from "../../../apis/exercise";

function TeacherAssignment(props) {
    const navigate = useNavigate();
    const { me, selectedClass, setBreadcrumb } = useContext(StoreContext);
    const [isOpen, setIsOpen] = useState({ open: false });
    const { t } = useTranslation();

    const {
        isError,
        data: teacherAssignments,
        isLoading,
        isFetching,
        refetch,
    } = useQuery(
        [selectedClass?.id],
        async () => {
            const res = await handleFetchApi(
                AASSIGNMENT.search({ params: `?class_id=${selectedClass?.id ?? null}&course_id=${selectedClass?.courseId}` })
            );

            return res?.data?.sort((a, b) => b?.assignment?.created_at - a?.assignment?.created_at);
        },
        {
            staleTime: 18000,
            cacheTime: 18000,
            refetchOnWindowFocus: false,
        }
    );

    const deleteAssignment = useMutation({
        mutationFn: (assignmentId) => {
            message.loading(t("deleting_data"));
            return handleFetchApi(AASSIGNMENT.delete({ assignmentId }));
        },
        onSuccess: async (response, variables) => {
            if (response?.status === "S0000") {
                message.destroy();
                notification.success({ message: t("delete_success") });
                refetch();
            } else notification.error({ message: t("cannot_delete_assignment") });
            return;
        },
        onError: () => message.error(t("feature_not_updated")),
    });

    const columns = [
        {
            title: t("test_assignment_name"),
            dataIndex: "name",
            key: "name",
            render: (value, record) => (
                <div className="flex__start__center gap">
                    {record?.assignment?.type === "TEST" ? <CustomTestIcon /> : <CustomExerciseIcon />}{" "}
                    <Typography.Text className="--nowrap">{record?.assignment?.name}</Typography.Text>
                </div>
            ),
        },
        {
            title: t("type"),
            dataIndex: "type",
            key: "type",
            render: (value, record) => (
                <Tag color={record?.assignment?.type === "TEST" ? "#FFEB5B" : "#BBDAFF"}>{t(record?.assignment?.type?.toLowerCase())}</Tag>
            ),
            filters: [
                { text: t("test"), value: "TEST" },
                { text: t("assignment"), value: "NORMAL" },
            ],
            onFilter: (value, record) => record?.assignment?.type === value,
        },
        {
            title: t("created_date"),
            dataIndex: "created_at",
            key: "created_at",
            render: (value, record) => moment(record?.assignment?.created_at).format("DD/MM/YYYY"),
        },
        {
            title: t("deadline"),
            dataIndex: "deadlineAt",
            key: "deadlineAt",
            render: (value, record) =>
                record?.assignment?.deadlineAt ? (
                    moment(record?.assignment?.deadlineAt).format("DD/MM/YYYY - HH:mm")
                ) : (
                    <Button type="link" style={{ padding: 0 }}>
                        {t("add_time")}
                    </Button>
                ),
        },
        {
            title: t("completed"),
            dataIndex: "per_of_student_completed",
            key: "per_of_student_completed",
            render: (value) => value ?? "0/0",
        },
        {
            title: null,
            dataIndex: "actions",
            key: "actions",
            render: (value, record) => (
                <Space>
                    <Button
                        type="text"
                        icon={<CustomEditIcon />}
                        onClick={() => {
                            if (record?.assignment?.type === "TEST_OFFLINE") {
                                return;
                            }
                            setBreadcrumb({
                                label: record?.assignment?.name,
                                link: "/",
                                oldLabel: "dashboard",
                            });
                            navigate(`/manage/assignment/${record?.assignment?.id}`);
                        }}
                    />

                    <Button
                        onClick={() => {
                            Modal.confirm({
                                icon: <CustomWarningIcon />,
                                title: t("confirm_delete"),
                                content: t("delete_assignment_warning"),
                                okText: t("delete"),
                                cancelText: t("cancel"),
                                okType: "danger",
                                onOk: () => deleteAssignment.mutate(record?.assignment?.id),
                            });
                        }}
                        danger
                        type="text"
                        icon={<CustomTrashIcon />}
                    />
                </Space>
            ),
        },
    ];

    const items = [
        { label: <span onClick={() => setIsOpen({ open: true, type: "TEST" })}>{t("assign_test")}</span>, key: "TEST" },
        { label: <span onClick={() => setIsOpen({ open: true, type: "NORMAL" })}>{t("assign_homework")}</span>, key: "NORMAL" },
        { label: <span onClick={() => setIsOpen({ open: true, type: "NO-GRADING" })}>{t("assign_reading")}</span>, key: "NO-GRADING" },
    ];

    return (
        <div className="loading_container">
            <Typography.Title level={4} className="flex__between__center">
                {t("assigned_list")}
                <Dropdown trigger={["click"]} menu={{ items }} placement="bottomRight">
                    <Button icon={<PlusOutlined />} type="primary" className="--md">
                        {t("add_new")}
                    </Button>
                </Dropdown>
            </Typography.Title>
            <Table pagination={{ pageSize: 8 }} dataSource={teacherAssignments} columns={columns} rowKey={(record) => record?.assignment?.id} />
            {(isLoading || isFetching) && <Loading />}
            {isOpen && (
                <GiveAssignment
                    type={isOpen?.type}
                    isOpen={isOpen?.open}
                    onClose={() => {
                        refetch();
                        setIsOpen({
                            open: false,
                        });
                    }}
                />
            )}
        </div>
    );
}

export default TeacherAssignment;
