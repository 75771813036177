import { Layout } from "antd";
import React from "react";
import SideBar from "../layouts/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import { Outlet } from "react-router-dom";

function Root() {
    return (
        <Layout>
            <SideBar />
            <Layout>
                <HeaderDashboard />
                <Outlet />
            </Layout>
        </Layout>
    );
}

export default Root;
