import React, { useContext } from "react";
import { Radar } from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js";
import { useTranslation } from "react-i18next";
import { cacheQueryConfig, handleFetchApi } from "../../configs/apiRequest";
import { getMonthRange } from "../../utils/datetime";
import { useQuery } from "@tanstack/react-query";
import { StoreContext } from "../../stores";
import Loading from "../Loading";
import { AASSIGNMENT } from "../../apis/exercise";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const StudentChartSkill = ({ studentId }) => {
    const { me } = useContext(StoreContext);
    const { t } = useTranslation();
    const {
        data: dataSkill,
        isLoading,
        isError,
    } = useQuery(
        ["score", studentId],
        async () => {
            const { from, to } = getMonthRange();
            const response = await handleFetchApi(AASSIGNMENT.getScoreByUser({ params: `${studentId || me?.id}?fromDate=${from}&toDate=${to}` }));
            return response?.data;
        },
        cacheQueryConfig({ time: 3 })
    );

    const data = {
        labels: ["Nghe", "Nói", "Đọc", "Viết", ["Từ ", "vựng"], ["Ngữ ", "pháp"]],
        datasets: [
            {
                label: null,
                data: [dataSkill?.listening_score, dataSkill?.speaking_score, dataSkill?.reading_score, dataSkill?.writing_score, 0, 0],
                backgroundColor: "#FFF6A860",
                borderColor: "#F3A000",
                borderWidth: 2,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                min: 0,
                max: 10,
                beginAtZero: true,
                angleLines: {
                    display: false,
                },
                ticks: {
                    display: true,
                    stepSize: 2,
                },
                pointLabels: {
                    font: {
                        size: 16,
                        weight: "700",
                        family: "Nunito",
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    // Check if the device supports rendering ChartJS
    const isTablet = navigator?.userAgent?.includes("Tablet") || navigator?.userAgent?.includes("Android");

    if (isTablet) {
        return <div>Chart not supported on this tablet.</div>;
    }

    if (isLoading) return <Loading />;
    return (
        <div className="chart-container">
            <Radar style={{ width: 350, height: 350, margin: "0 auto 1rem auto" }} data={data} options={options} />
        </div>
    );
};

export default StudentChartSkill;
