import logo_text from "../assets/images/oeg_text.png";
import logo_without_text from "../assets/images/LOGO.png";
import oeg_login from "../assets/images/oeg_login.png";
import oeg_forgot_password from "../assets/images/oeg_forgot_password.png";

import logo_bee from "../assets/images/logo_text.png"
import logo_bee_without_text from "../assets/images/logo_without_text.png"
import bee_login from "../assets/images/bee_login.png";
import bee_sad from "../assets/images/bee_sad.png";

export const oegConfigs = {
    brandName: 'Oxbridge Group',
    logoWithText: logo_text,
    logoWithoutText: logo_without_text,
    login: oeg_login,
    forgotPassword: oeg_forgot_password,
}

export const beeConfigs = {
    brandName: 'Bee House',
    logoWithText: logo_bee,
    logoWithoutText: logo_bee_without_text,
    login: bee_login,
    forgotPassword: bee_sad,
}