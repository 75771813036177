import { Modal } from "antd";
import Cookies from "js-cookie";
import CustomSuccessIcon from "../../../components/Icons/CustomSuccessIcon";
import { MenuByRole } from "../../../configs/menuByRole";
import { isTeacher } from "../../../configs/enums";

const AUTH_ROUTER = ["/forgot_password", "/set_new_password", "/login"];

export const saveUserSession = ({ response, setMe, t }) => {
    let me = response.data;
    Cookies.set("access_token", me.access_token, { expires: 7 });
    Cookies.set("refresh_token", me.refresh_token, { expires: 60 });
    localStorage.setItem("email_verified", me?.user?.email_verified);
    Modal.success({
        title: t("login_success_msg"),
        icon: <CustomSuccessIcon />,
        content: "Chào mừng đến với Cloudbook, chúc bạn học tập vui vẻ!",
        centered: true,
    });
    setMe(me);
};

export const linkToDashboard = ({ me }) => {
    let callbackUrl = window.location.search.split("=")[1];
    let defaultUrl = "/";
    // ??? :D
    if (AUTH_ROUTER.includes(callbackUrl)) {
        callbackUrl = "/";
    }
    if (!isTeacher(me?.user)) {
        defaultUrl = MenuByRole[me?.user?.role][0]?.link;
    }

    setTimeout(() => {
        window.location = `${callbackUrl ? `${callbackUrl}?last=/login` : defaultUrl}`;
    }, 1000);
};
