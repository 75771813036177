import { Layout, Segmented } from "antd";
import React, { useState } from "react";
import UnSubmitted from "./components/UnSubmitted";
import Submitted from "./components/Submitted";
const { Content } = Layout;

function StudentAssignment(props) {
    const [viewMode, setViewMode] = useState('UNSUBMITTED');

    const renderDashboardByRole = () => {
        switch (viewMode) {
            case 'UNSUBMITTED':
                return <UnSubmitted />
            case 'SUBMITTED':
                return <Submitted />
            default:
                 return <UnSubmitted />
        }
    };

    return (
        <Content>
            <div className="page student_assignment">
                <Segmented
                    onChange={(value) => setViewMode(value)}
                    size="large"
                    options={[
                        {
                            label: "Chưa hoàn thành",
                            value: "UNSUBMITTED",
                        },
                        {
                            label: "Đã hoàn thành",
                            value: "SUBMITTED",
                        },
                    ]}
                />
                {
                    renderDashboardByRole()
                }
            </div>
        </Content>
    );
}

export default StudentAssignment;
