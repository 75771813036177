import React, { useState } from "react";
import { Table, Spin, Layout, Button, Input, Drawer } from "antd";
import { useQuery } from "@tanstack/react-query"; // Updated import
import { handleFetchApi } from "../../configs/apiRequest";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import SchoolDetail from "./components/SchoolDetail";
import { AEDUCATION } from "../../apis/education";

const ManageSchool = () => {
    const {t} = useTranslation()
    const { data, isLoading, error } = useQuery(["schools"], () => handleFetchApi(AEDUCATION.getSchool()));
    const [drawer, setDrawer] = useState({
        open: false,
        data: null
    });

    const columns = [
        {
            title: "School Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            render: (value, record) => <Button onClick={() => setDrawer({
                open: true,
                data: record
            })} type="link">CHI TIẾT</Button>
        },
    ];

    if (error) return <div>Error fetching schools</div>;

    return (
        <Layout.Content>
            <div className="page">
                <div className="flex__between__center gap">
                    <Input size="large" placeholder={t('search')} />
                    <Button size="large" type="primary" icon={<PlusOutlined />}>Thêm trường học</Button>
                </div>
                <Table dataSource={data?.data} columns={columns} rowKey="id" />
                {
                    isLoading && <Loading />
                }
            </div>
            <SchoolDetail open={drawer.open} data={drawer.data} close={() => setDrawer({open: false})} />
        </Layout.Content>
    );
};

export default ManageSchool;
