import React, { useState, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { Layout, Segmented } from "antd";
import DailyReport from "./components/DailyReport";
import WeeklyReport from "./components/WeeklyReport";
import MonthlyReport from "./components/MonthlyReport";

const { Content } = Layout;

function StudentReport() {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState("daily");

    const segmentedOptions = useMemo(() => [
        { label: t('daily'), value: "daily" },
        { label: t('monthly'), value: "weekly" },
        { label: t('end_of_term'), value: "monthly" }
    ], [t]);

    const renderViewMode = () => {
        switch (viewMode) {
            case "daily":
                return <DailyReport />;
            case "weekly":
                return <WeeklyReport />;
            case "monthly":
                return <MonthlyReport />;
            default:
                return <DailyReport />;
        }
    };

    return (
        <Content>
            <div className="page student_report">
                <Segmented
                    onChange={setViewMode}
                    size="large"
                    options={segmentedOptions}
                />
                {renderViewMode()}
            </div>
        </Content>
    );
}

export default StudentReport;
