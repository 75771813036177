import React, { Fragment, useContext, useRef, useState } from "react";
import { Button, Typography } from "antd";
import { StoreContext } from "../../../stores";
import { CaretRightOutlined, CloseOutlined, LeftOutlined, PauseOutlined, RightOutlined, SoundOutlined } from "@ant-design/icons";
import icon_playing from "../../../assets/images/icon-playing.gif";
import audio_image from "../../../assets/images/audio_image.png";
import ReactPlayer from "react-player";
import { isEmpty } from "../../../utils";

function Audio(props) {
    const { media, setMedia } = useContext(StoreContext);
    const [audioStatus, setAudioStatus] = useState("play");
    const audio = useRef();
    if (isEmpty(media)) return;
    const currentAudio = media?.list?.filter((item) => item?.id === media?.current)[0];
    const renderListAudio = () => {
        if (media?.mode === "Exercise") return;
        return media?.list?.map((element) => (
            <div
                key={element?.id}
                onClick={() => {
                    // setCurrentIndex(index);
                    setAudioStatus("play");
                    setMedia((prevState) => ({
                        ...prevState,
                        current: element?.id,
                    }));
                    // setSelectedExercise(element);
                }}
                className={`cb_audio__list__item ${media?.current === element?.id ? "--playing" : ""}`}
            >
                {element.name || "AUDIO"}
                {media?.current === element?.id && <img src={icon_playing} alt="playing" />}
            </div>
        ));
    };

    const handleNextAudio = () => {
        let currentIndex = media?.list?.findIndex((e) => e.id === media?.current);
        if (currentIndex === media?.list?.length - 1) return;
        setMedia((prev) => ({ ...prev, current: media?.list[currentIndex + 1]?.id }));
    };
    
    const handlePrevAudio = () => {
        let currentIndex = media?.list?.findIndex((e) => e.id === media?.current);
        if (currentIndex === 0) return;
        setMedia((prev) => ({ ...prev, current: media?.list[currentIndex - 1]?.id }));
    };

    return (
        currentAudio?.type === "AUDIO" && (
            <>
                <div className="cb_audio flex__center__center flex__column">
                    <Typography.Text>
                        <SoundOutlined /> {currentAudio?.name || "AUDIO"}
                        {media?.controls && (
                            <Button
                                onClick={() => setMedia((prev) => ({ ...prev, current: null }))}
                                icon={<CloseOutlined />}
                                type="text"
                                shape="circle"
                                size="small"
                            />
                        )}
                    </Typography.Text>
                    {media?.controls && (
                        <>
                            <img className="audio_image" src={audio_image} alt="audio_image" />
                            <div className="cb_audio__controls flex__center__center">
                                <Button onClick={handlePrevAudio} type="text" size="large" icon={<LeftOutlined />} shape="circle" />
                                <Button
                                    onClick={() => {
                                        if (audioStatus === "play") {
                                            setAudioStatus("pause");
                                        } else {
                                            setAudioStatus("play");
                                        }
                                    }}
                                    type="text"
                                    size="large"
                                    icon={audioStatus === "play" ? <PauseOutlined /> : <CaretRightOutlined />}
                                    shape="circle"
                                />
                                <Button onClick={handleNextAudio} type="text" size="large" icon={<RightOutlined />} shape="circle" />
                            </div>
                        </>
                    )}
                    <ReactPlayer
                        url={currentAudio?.url}
                        ref={audio}
                        onPause={() => setAudioStatus("pause")}
                        onPlay={() => setAudioStatus("play")}
                        controls={true}
                        width={250}
                        height={50}
                        playing={media?.autoPlay || audioStatus === "play"}
                        playIcon={<></>}
                        // onEnded={() => {
                        //     if (!media?.controls) setMedia({});
                        // }}
                    />

                    <div className="cb_audio__list">{renderListAudio()}</div>
                </div>
            </>
        )
    );
}

export default Audio;
