import Icon from "@ant-design/icons";
import React from "react";

function CustomDangerIcon(props) {
    const SvgPath = () => (
        <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="72" height="72" rx="36" fill="#FFE2E2" />
            <path
                d="M36.5 28V36M36.5 44H36.52M56.5 36C56.5 47.0457 47.5457 56 36.5 56C25.4543 56 16.5 47.0457 16.5 36C16.5 24.9543 25.4543 16 36.5 16C47.5457 16 56.5 24.9543 56.5 36Z"
                stroke="#FF3A3A"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomDangerIcon;
