import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import moment from "moment";
import { getMonthRange } from "../../../utils/datetime";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import { useTranslation } from "react-i18next";
import { AAUTH } from "../../../apis/auth";

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend);

const StudentPerformance = () => {
    const { t } = useTranslation();

    const options = {
        // responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: false,
            },
        },
    };

    const thisMonth = getMonthRange();

    const {
        data: sp,
        isLoading,
        isError,
    } = useQuery(
        ["student_performance"],
        async () => {
            const online = await handleFetchApi(AAUTH.getTracking({ timeRange: thisMonth, type: "DOING" }));
            const doing = await handleFetchApi(AAUTH.getTracking({ timeRange: thisMonth, type: "DOING_TEST_ASSIGNMENT" }));
            return {
                online: online?.data,
                doing: doing?.data,
            };
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const labels = sp?.online?.map((s) => moment(s.execution_date).format("DD/MM/YYYY"));

    const data = {
        labels,
        datasets: [
            {
                label: t("online_time_minutes"),
                data: sp?.online?.map((s) => s.minutes),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
            },
            {
                label: t("assignment_time_minutes"),
                data: sp?.doing?.map((s) => s.minutes),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
                borderColor: "rgba(53, 162, 235, 1)",
                fill: false,
            },
        ],
    };

    if (isLoading) return <Loading />;
    if (isError) return <NoData />;

    return (
        <div className="student_profile__ranking">
            <Line
                style={{
                    display: "inline-block",
                }}
                options={options}
                data={data}
            />
        </div>
    );
};

export default StudentPerformance;
