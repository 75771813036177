import Icon from "@ant-design/icons";
import React from "react";

function CustomPhoneIcon(props) {
    const SvgPath = () => (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.87832 9.64046C9.57432 11.0901 10.5231 12.4487 11.7247 13.6503C12.9263 14.8519 14.2849 15.8007 15.7345 16.4967C15.8592 16.5565 15.9216 16.5865 16.0005 16.6095C16.2808 16.6912 16.6251 16.6325 16.8625 16.4625C16.9293 16.4146 16.9865 16.3575 17.1008 16.2432C17.4504 15.8936 17.6252 15.7188 17.801 15.6045C18.4639 15.1735 19.3184 15.1735 19.9813 15.6045C20.1571 15.7188 20.3319 15.8936 20.6815 16.2432L20.8764 16.438C21.4078 16.9695 21.6735 17.2352 21.8179 17.5206C22.105 18.0881 22.105 18.7584 21.8179 19.326C21.6735 19.6113 21.4078 19.8771 20.8764 20.4085L20.7187 20.5661C20.1891 21.0958 19.9243 21.3606 19.5643 21.5628C19.1648 21.7872 18.5443 21.9486 18.0861 21.9472C17.6731 21.946 17.3909 21.8659 16.8264 21.7057C13.793 20.8447 10.9307 19.2203 8.54271 16.8323C6.15473 14.4443 4.53025 11.5819 3.66928 8.54854C3.50908 7.9841 3.42897 7.70187 3.42774 7.28893C3.42638 6.83071 3.58774 6.21021 3.81216 5.81071C4.01441 5.45068 4.27922 5.18587 4.80884 4.65624L4.96648 4.49861C5.49792 3.96717 5.76364 3.70144 6.04902 3.5571C6.61658 3.27003 7.28685 3.27003 7.85441 3.5571C8.13979 3.70144 8.40551 3.96716 8.93695 4.49861L9.13182 4.69348C9.48143 5.04308 9.65623 5.21789 9.77052 5.39366C10.2015 6.05655 10.2015 6.91114 9.77052 7.57403C9.65623 7.7498 9.48143 7.92461 9.13182 8.27421C9.01751 8.38853 8.96036 8.44568 8.91252 8.51249C8.7425 8.74992 8.6838 9.09418 8.76553 9.37454C8.78852 9.45343 8.81846 9.51577 8.87832 9.64046Z"
                stroke="#666460"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const CustomIcon = (props) => <Icon component={SvgPath} {...props} />;

    return <CustomIcon />;
}

export default CustomPhoneIcon;
